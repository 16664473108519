import "./ChatBanner.less";
import { useAppSelector } from "../hooks";
import { PlatinumBotChatBannerMessage } from "./PlatinumChatBotBannerMessage";
import PlatinumChatWithRepBannerMessage from "./PlatinumChatWithRepBannerMessage";

export enum CustomerChatMessageEnum {
    NONE,
    PLATINUM_BOT_CHAT_BANNER_MESSAGE,
    PLATINUM_CHAT_WITH_REP_BANNER_MESSAGE,
}

const CustomerChatBanner = (): JSX.Element => {
    const chatBanner = useAppSelector(
        (state) => state.customerApp.chatBannerState
    );

    if (!chatBanner.active) {
        return <></>;
    }

    function getMessage(): JSX.Element {
        switch (chatBanner.chatBannerMessage) {
            case CustomerChatMessageEnum.PLATINUM_BOT_CHAT_BANNER_MESSAGE:
                return <PlatinumBotChatBannerMessage />;
            case CustomerChatMessageEnum.PLATINUM_CHAT_WITH_REP_BANNER_MESSAGE:
                return <PlatinumChatWithRepBannerMessage />;
            default:
                return <></>;
        }
    }

    return (
        <div className="chat-banner" data-testid="customer-chat-banner">
            {getMessage()}
        </div>
    );
};

export default CustomerChatBanner;
