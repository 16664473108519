import { render as ReactDomRender } from "react-dom";
import CustomerAppWrapper from "./CustomerAppWrapper";
import CustomerApp from "@Components/CustomerApp";
import ErrorBoundaryForApp from "@Components/shared/RenderError/ErrorBoundaryForApp";
import withChatHubDisconnect from "@Components/CustomerApp/WithChatHubDisconnect/WithChatHubDisconnect";
import NewMessageWindowWrapper from "@Components/CustomerApp/NewMessageWindowWrapper/NewMessageWindowWrapper";

ReactDomRender(
    <CustomerAppWrapper>
        <ErrorBoundaryForApp wrapper={withChatHubDisconnect}>
            <NewMessageWindowWrapper>
                <CustomerApp />
            </NewMessageWindowWrapper>
        </ErrorBoundaryForApp>
    </CustomerAppWrapper>,
    document.getElementById("root")
);
