import { IssueInformationModel } from "@Models/chat/IssueInformationModel";
import { WelcomeIssue } from "@Models/chat/WelcomeIssue";
import { AutomationActionIds } from "./AutomationActionIds";

function requiredOrderNumberParameterPopulated(
    queryParams: Record<string, string>
): boolean {
    const orderNumber = queryParams.orderNumber;
    return (
        orderNumber !== undefined && orderNumber !== "" && orderNumber !== null
    );
}

export function allParametersExistAndHaveValuesForAutomationAction(
    queryParams: Record<string, string>
): boolean {
    switch (queryParams.actionId) {
        case AutomationActionIds.return:
        case AutomationActionIds.damage:
            return requiredOrderNumberParameterPopulated(queryParams);
        default:
            return false;
    }
}

export function getIssueInformationModelForAutomationAction(
    queryParams: Record<string, string>
): IssueInformationModel | undefined {
    switch (queryParams.actionId) {
        case AutomationActionIds.return:
        case AutomationActionIds.damage:
            return {
                issue: WelcomeIssue.Order,
            };
        default:
            return undefined;
    }
}
