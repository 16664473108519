import React from "react";
import {
    Space,
    Typography,
    List,
    Checkbox,
    Image,
    Radio,
    Alert,
    InputNumber,
    Divider,
} from "antd";
import { individual, multiple } from "../../Definitions";
import "./SelectableListItem.less";

const { Text, Link } = Typography;

const SelectableListItem = (props: any): React.JSX.Element => {
    const {
        item,
        resolutionList,
        setResolutionList,
        itemList,
        setItemList,
        setFormButtonClicked,
        readOnly: isReadOnly,
        isSubmitting,
        quantityHeader,
        quantityWarningTextUnitsPerPackagingEqualsOne,
        quantityWarningTextUnitsPerPackagingGreaterThanOne,
        allowIndividualItems,
        "data-testid": dataTestId,
        additionalCheckBoxHandlerFunction,
        orderInfo: { issue: orderIssue = "default-warning" } = {},
    } = props;
    const index = itemList.indexOf(item);

    const maxNumberInput = (): number => {
        if (item.unitsPerPackaging === 1) {
            return item.quantity;
        } else if (
            item.unitsPerPackaging > 1 &&
            item.returnType === individual
        ) {
            return item.unitsPerPackaging * item.quantity;
        } else if (item.unitsPerPackaging > 1 && item.returnType === multiple) {
            return item.quantity;
        } else {
            return item.quantity;
        }
    };

    const shouldDisplayQuantityWarning = (item: any): boolean => {
        return (
            item.isChecked &&
            item.quantity > 1 &&
            (item.resolutionQuantity < 1 ||
                (item.unitsPerPackaging > 1 &&
                    item.returnType === null &&
                    allowIndividualItems))
        );
    };

    const getQuantityWarningForItemForScreenReaders = (item: any): string => {
        if (!shouldDisplayQuantityWarning(item)) {
            return "";
        }
        return `-${orderIssue
            .replace(/ /g, "-")
            .toLowerCase()
            .concat("__item#", item.clarkitbizItemNumberId)}`;
    };

    const individualOrUoMRadioGroupOnChangeHandler = (e: any): void => {
        const itemIndex = resolutionList.findIndex(
            (listItem: any) =>
                listItem.clarkitbizOrderItemId === item.clarkitbizOrderItemId
        );

        itemList[index].returnType = e.target.value;
        setItemList([...itemList]);

        if (e.target.value && item.resolutionQuantity > 0) {
            if (
                !resolutionList.some(
                    (listItem: any) =>
                        listItem.clarkitbizOrderItemId ===
                        item.clarkitbizOrderItemId
                )
            ) {
                setResolutionList([
                    ...resolutionList,
                    {
                        ...itemList[index],
                        returnType: e.target.value,
                    },
                ]);
            } else if (
                resolutionList.some(
                    (listItem: any) =>
                        listItem.clarkitbizOrderItemId ===
                        item.clarkitbizOrderItemId
                )
            ) {
                resolutionList.splice(itemIndex, 1, {
                    ...itemList[index],
                    returnType: e.target.value,
                });
                setResolutionList([...resolutionList]);
            }
        }

        if (
            resolutionList.some(
                (listItem: any) =>
                    listItem.clarkitbizOrderItemId ===
                    item.clarkitbizOrderItemId
            )
        ) {
            resolutionList.splice(itemIndex, 1, {
                ...itemList[index],
                resolutionQuantity: 0,
            });
            setResolutionList([...resolutionList]);

            itemList[index].resolutionQuantity = 0;
            setItemList([...itemList]);
        }
    };

    const checkboxOnChangeHandler = (e: any): void => {
        setFormButtonClicked(null);

        itemList[index].isChecked = e.target.checked;
        itemList[index].resolutionQuantity = 0;
        itemList[index].returnType = null;
        setItemList([...itemList]);

        if (
            item.quantity === 1 &&
            (item.unitsPerPackaging === 1 || !allowIndividualItems)
        ) {
            itemList[index].resolutionQuantity = 1;
            setItemList([...itemList]);
            setResolutionList([...resolutionList, item]);
        }

        if (
            resolutionList.some(
                (listItem: any) =>
                    listItem.clarkitbizOrderItemId ===
                    item.clarkitbizOrderItemId
            )
        ) {
            resolutionList.splice(
                resolutionList.findIndex(
                    (listItem: any) =>
                        listItem.clarkitbizOrderItemId ===
                        item.clarkitbizOrderItemId
                ),
                1
            );
            setResolutionList([...resolutionList]);
        }
        additionalCheckBoxHandlerFunction?.();
    };

    const inputNumberChangeHandler = (value: any): void => {
        itemList[index].resolutionQuantity = value;
        setItemList([...itemList]);

        const itemIndex = resolutionList.findIndex(
            (listItem: any) =>
                listItem.clarkitbizOrderItemId === item.clarkitbizOrderItemId
        );

        if (
            item.returnType ||
            item.unitsPerPackaging === 1 ||
            !allowIndividualItems
        ) {
            if (
                !resolutionList.some(
                    (listItem: any) =>
                        listItem.clarkitbizOrderItemId ===
                        item.clarkitbizOrderItemId
                )
            ) {
                setResolutionList([...resolutionList, itemList[index]]);
            } else if (
                resolutionList.some(
                    (listItem: any) =>
                        listItem.clarkitbizOrderItemId ===
                        item.clarkitbizOrderItemId
                )
            ) {
                resolutionList.splice(itemIndex, 1, itemList[index]);
                setResolutionList([...resolutionList]);
            }
        }
    };

    const itemAddSuccessMessage = `${item.itemDescription} has been added to Items & Preferred Resolution section.`;

    return (
        <List.Item
            className="item-issues__list-item"
            data-testid={`${dataTestId}-selectable-list-item`}
        >
            <Space
                size={0}
                className="item-issues__list-item__wrapper"
                data-testid="selectable-list-item-container"
            >
                {!isReadOnly && (
                    <Checkbox
                        checked={item.isChecked}
                        disabled={isSubmitting}
                        onChange={checkboxOnChangeHandler}
                        data-testid="selectable-list-item-checkbox"
                    />
                )}
                <Text data-testid="selectable-list-item-quantity">{`${item.quantity} Qty`}</Text>
                <Space
                    size={0}
                    className="item-issues__list-item__info"
                    data-testid="selectable-list-item-info"
                >
                    <Image
                        src={item.itemThumbnailUrl}
                        preview={false}
                        alt=""
                        data-testid="selectable-list-item-image"
                    />
                    <Space size={0}>
                        <Space
                            size={0}
                            className="item-issues__list-item__info__link"
                            data-testid="selectable-list-item-link-container"
                        >
                            <Link
                                underline
                                href={item.storeUrl}
                                target="_blank"
                                data-testid="selectable-list-item-link"
                            >
                                {item.itemDescription}
                            </Link>
                        </Space>
                        <Space
                            size={0}
                            className="item-issues__list-item__price"
                            data-testid="selectable-list-item-price-container"
                        >
                            <Text data-testid="selectable-list-item-item-number">
                                <span className="sr-only">Item</span>#
                                {(item.itemNumber as string).toUpperCase()}
                            </Text>
                            <Text data-testid="selectable-list-item-price">
                                ${item.price.toFixed(2)}
                            </Text>
                        </Space>
                    </Space>
                </Space>
            </Space>
            <span className="sr-only" aria-live="polite">
                {item.isChecked && itemAddSuccessMessage}
            </span>
            {item.isChecked &&
                (item.quantity > 1 ||
                    (item.unitsPerPackaging > 1 && allowIndividualItems)) && (
                    <Space
                        size={0}
                        className="item-issues__list-item__damage-type"
                        data-testid="selectable-list-item-quantity-entry-container"
                    >
                        <Text data-testid="selectable-list-item-quantity-entry-header-text">
                            {quantityHeader}
                        </Text>
                        <Space size={0} style={{ width: "100%" }}>
                            <InputNumber
                                size="small"
                                controls={false}
                                value={item.resolutionQuantity}
                                min={0}
                                max={maxNumberInput()}
                                disabled={isSubmitting}
                                onChange={inputNumberChangeHandler}
                                data-testid="selectable-list-item-quantity-entry"
                                aria-describedby={`item-quantity-alert${getQuantityWarningForItemForScreenReaders(
                                    item
                                )}`}
                            />
                            {item.unitsPerPackaging > 1 &&
                                allowIndividualItems && (
                                    <>
                                        <Divider type="vertical" />
                                        <Radio.Group
                                            value={item.returnType}
                                            disabled={isSubmitting}
                                            onChange={
                                                individualOrUoMRadioGroupOnChangeHandler
                                            }
                                            data-testid="selectable-list-item-individual-or-uom-radio-group"
                                        >
                                            <Space direction="vertical">
                                                <Radio
                                                    value={individual}
                                                    data-testid="selectable-list-item-individual-radio-button"
                                                >
                                                    Individual Items (Max{" "}
                                                    {item.unitsPerPackaging ===
                                                    1
                                                        ? item.quantity
                                                        : item.unitsPerPackaging *
                                                          item.quantity}
                                                    )
                                                </Radio>
                                                <Radio
                                                    value={multiple}
                                                    data-testid="selectable-list-item-uom-radio-button"
                                                >
                                                    {item.unitOfMeasure}s (Max{" "}
                                                    {item.quantity})
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </>
                                )}
                        </Space>
                    </Space>
                )}
            <div
                aria-live="polite"
                id={`item-quantity-alert${getQuantityWarningForItemForScreenReaders(
                    item
                )}`}
            >
                {shouldDisplayQuantityWarning(item) && (
                    <Alert
                        type="warning"
                        showIcon
                        message={
                            item.unitsPerPackaging > 1 && allowIndividualItems
                                ? quantityWarningTextUnitsPerPackagingGreaterThanOne
                                : quantityWarningTextUnitsPerPackagingEqualsOne
                        }
                        data-testid="selectable-list-item-quantity-warning"
                        aria-label="important message"
                    />
                )}
            </div>
        </List.Item>
    );
};

export default SelectableListItem;
