import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { BaseMessage } from "@Hubs/chat/dtos/BaseMessage";
import { CreateChatResponse } from "@Api/dtos/chat/CreateChatResponse";
import { SwitchboardConfig } from "@Models/SwitchboardConfig";
import { TypingIndicator } from "@Models/typingIndicator/TypingIndicator";
import { ChatApi } from "@Api/Customer/ChatApi";
import { MessageApi } from "@Api/Customer/MessageApi";
import { CustomerApi } from "@Api/CustomerApi";
import { ApplicationSettingsApi } from "@Api/Customer/ApplicationSettingsApi";
import { ValidationApi } from "@Api/ValidationApi";
import { FeedbackReasonsApi } from "@Api/Customer/FeedbackReasonsApi";
import axios from "axios";
import {
    mergeMessages,
    suppressMessagesForCustomer,
} from "@Src/utilities/MessageHelper";
import { PersistedMessage } from "@Src/hubs/chat/dtos/PersistedMessage";
import { UserJoinedChatMessage } from "@Src/hubs/chat/dtos/UserJoinedChatMessage";
import { ClientRole } from "@Src/hubs/chat/dtos/ClientRole";
import { PersistedMessageType } from "@Src/hubs/chat/dtos/PersistedMessageType";
import { ChatCreatedMessage } from "@Models/chat/ChatCreatedMessage";
import { ChatUser } from "@Models/chat/ChatUser";
import { UserLeftChatMessage } from "@Src/hubs/chat/dtos/UserLeftChatMessage";
import { FailedTextMessage } from "@Models/chat/FailedTextMessage";
import { ReconnectingMessage } from "@Models/chat/ReconnectingMessage";
import { JoinChatAsCustomerResponse } from "@Api/dtos/chat/JoinChatAsCustomerResponse";
import { ChatConnectionState } from "@Src/hubs/chat/ChatConnectionState";
import { ViewState } from "./viewState";
import { CloseReason } from "./closeReason";
import { ChatState } from "@Models/chat/ChatState";
import { ClientMessageType } from "@Src/hubs/chat/dtos/ClientMessageType";
import { SmartIncludeMessage } from "@Src/hubs/chat/dtos/SmartIncludeMessage";
import { MessageToDeleteFromChat } from "@Src/hubs/chat/dtos/MessageToDeleteFromChat";
import { CustomerApplicationSettings } from "@Api/dtos/applicationSettings/CustomerApplicationSettings";
import { NotHelpfulReason } from "@Models/chat/NotHelpfulReason";
import {
    playReceivedMessageSound,
    playSentMessageSound,
} from "@Utilities/SoundNotifications";
import { removeAll } from "@Utilities/ArrayHelper";
import { CustomerChatModel } from "@Models/chat/CustomerChatModel";
import { OpenChatResponse } from "@Api/dtos/chat/OpenChatResponse";
import { RootState } from "./store";
import { ChatBotApi } from "@Api/Customer/ChatBotApi";
import { FeedbackReasonsModel } from "@Api/dtos/feedbackReasons/FeedbackReasonsModel";
import { UpdateMessageJsonData } from "@Api/dtos/chat/UpdateMessageJsonData";
import { CustomActivityMessage } from "@Src/hubs/chat/dtos/CustomActivityMessage";
import {
    CustomerChatBannerStatus,
    CustomerChatMessageEnum,
} from "./CustomerChatBanner";

interface CustomerState {
    chatApi: ChatApi;
    messageApi: MessageApi;
    customerApi: CustomerApi;
    chatBotApi: ChatBotApi;
    applicationSettingsApi: ApplicationSettingsApi;
    validationApi: ValidationApi;
    feedbackReasonsApi: FeedbackReasonsApi;
    chatHubConnectionId: string | null;
    chatHubConnectionState: ChatConnectionState;
    config: SwitchboardConfig;

    messages: BaseMessage[];
    chat?: CustomerChatModel;
    firstOperatorJoinedMessage?: UserJoinedChatMessage;
    chatUser?: ChatUser;
    viewState: ViewState;
    hasChatEnded: boolean;
    applicationSettings?: CustomerApplicationSettings;
    operatorsOnline: boolean;
    botChatNotHelpfulReasons: NotHelpfulReason[];
    liveChatNotHelpfulReasons: NotHelpfulReason[];
    typingIndicators: TypingIndicator[];
    isScrolledToBottom: boolean;
    unreadMessageCountWhileScrolledUp: number;
    isFeedbackTimeoutBannerVisible: boolean;
    isWindowVisible: boolean;
    newMessagesReceivedWhileNotVisible: number;
    chatBannerState: CustomerChatBannerStatus;
}

const config: SwitchboardConfig = {
    switchboardUrl: `https://${location.host}`,
};

const initialState: CustomerState = {
    chatApi: new ChatApi(config, axios.create()),
    messageApi: new MessageApi(config, axios.create()),
    customerApi: new CustomerApi(config, axios.create()),
    chatBotApi: new ChatBotApi(config, axios.create()),
    applicationSettingsApi: new ApplicationSettingsApi(config, axios.create()),
    feedbackReasonsApi: new FeedbackReasonsApi(config, axios.create()),
    validationApi: new ValidationApi(config, axios.create()),
    chatHubConnectionState: ChatConnectionState.Uninitialized,
    config: config,

    chatHubConnectionId: null,
    messages: [],
    viewState: ViewState.Initializing,
    hasChatEnded: false,
    operatorsOnline: true,
    botChatNotHelpfulReasons: [],
    liveChatNotHelpfulReasons: [],
    typingIndicators: [],
    isScrolledToBottom: true,
    unreadMessageCountWhileScrolledUp: 0,
    isFeedbackTimeoutBannerVisible: false,
    isWindowVisible: true,
    newMessagesReceivedWhileNotVisible: 0,
    chatBannerState: {
        chatBannerMessage: CustomerChatMessageEnum.NONE,
        active: false,
    },
};

// These reducers cannot have side effects outside of updating state, meaning:
//   - Do not chain dispatches
//   - Do not start asynchronous logic (call an API, setInterval, or setTimeouts)
// Past tense naming is meant to enforce that these are meant to be "pure functions"
// that are just meant to record the event that happened into the app state.
const customerAppSlice = createSlice({
    name: "customerApp",
    initialState: initialState,
    reducers: {
        onChatHubConnected(state, action: PayloadAction<string>) {
            state.chatHubConnectionId = action.payload;
            state.chatHubConnectionState = ChatConnectionState.Connected;
        },

        onChatHubClosed(state) {
            // Only executed on ungraceful shutdown
            state.chatHubConnectionState = ChatConnectionState.Closed;
            state.viewState = ViewState.Error;
        },

        onChatHubReconnecting(state) {
            state.chatHubConnectionState = ChatConnectionState.Reconnecting;
        },

        onChatHubReconnected(
            state,
            action: PayloadAction<JoinChatAsCustomerResponse>
        ) {
            const response = action.payload;

            state.messages = suppressMessagesForCustomer(
                mergeMessages(state.messages, response.messages),
                false,
                state.applicationSettings?.operatorJoinLeftDelaySeconds
            );

            state.chatHubConnectionState = ChatConnectionState.Connected;
        },

        receivedMessage(state, action: PayloadAction<PersistedMessage>) {
            const message = action.payload;
            state.messages.push(message);

            if (message.clientRole !== ClientRole.Customer) {
                if (!state.isScrolledToBottom) {
                    state.unreadMessageCountWhileScrolledUp++;
                }
                playReceivedMessageSound();
            } else {
                playSentMessageSound();
            }

            if (!state.isWindowVisible) {
                state.newMessagesReceivedWhileNotVisible++;
            }
        },
        clearChatBanner(state) {
            state.chatBannerState.active = false;
            state.chatBannerState.chatBannerMessage =
                CustomerChatMessageEnum.NONE;
        },
        showChatBanner(state, action: PayloadAction<CustomerChatBannerStatus>) {
            state.chatBannerState = action.payload;
        },

        receivedSmartIncludeMessage(
            state,
            action: PayloadAction<SmartIncludeMessage>
        ) {
            state.messages.push(action.payload);

            if (action.payload.clientRole !== ClientRole.Customer) {
                if (!state.isScrolledToBottom) {
                    state.unreadMessageCountWhileScrolledUp++;
                }
                playReceivedMessageSound();
            } else {
                playSentMessageSound();
            }
        },

        receivedUserJoinedChatMessage(
            state,
            action: PayloadAction<UserJoinedChatMessage>
        ) {
            const joinedChatMessage = action.payload;

            if (joinedChatMessage.clientRole === ClientRole.Operator) {
                if (!state.firstOperatorJoinedMessage) {
                    state.firstOperatorJoinedMessage = joinedChatMessage;
                }
                state.viewState = ViewState.TalkingToRep;
                if (state.chat) {
                    state.chat.state = ChatState.TalkingToRep;
                }

                // Make sure to hide transfer to rep banner if it's visible and the operator joined
                if (
                    state.chatBannerState.chatBannerMessage ==
                    CustomerChatMessageEnum.PLATINUM_CHAT_WITH_REP_BANNER_MESSAGE
                ) {
                    state.chatBannerState.active = false;
                    state.chatBannerState.chatBannerMessage =
                        CustomerChatMessageEnum.NONE;
                }
            }

            state.messages = suppressMessagesForCustomer(
                mergeMessages(state.messages, [joinedChatMessage]),
                true,
                state.applicationSettings?.operatorJoinLeftDelaySeconds
            );
        },

        receivedUserLeftChatMessage(
            state,
            action: PayloadAction<UserLeftChatMessage>
        ) {
            if (action.payload.clientRole == ClientRole.Customer) {
                const message = action.payload;
                state.messages = suppressMessagesForCustomer(
                    mergeMessages(state.messages, [message]),
                    true,
                    state.applicationSettings?.operatorJoinLeftDelaySeconds
                );
            }

            state.typingIndicators = removeAll(
                state.typingIndicators,
                (typingIndicator) =>
                    typingIndicator.userId?.toString() ==
                    action.payload.senderUserId
            );
        },

        receivedMessageToDelete(
            state,
            action: PayloadAction<MessageToDeleteFromChat>
        ) {
            const messageToDelete = action.payload;
            const indexToDelete = state.messages.findIndex(
                (message) => message.id === messageToDelete.messageId
            );
            if (indexToDelete >= 0) {
                state.messages.splice(indexToDelete, 1);
            }
        },
        receivedUserIsTyping(state, action: PayloadAction<TypingIndicator>) {
            if (
                !action.payload.isFromCustomer &&
                !state.typingIndicators.some((value) => {
                    return value.userId === action.payload.userId;
                })
            ) {
                state.typingIndicators.push(action.payload);
            }
        },
        receiveUserStoppedTyping(
            state,
            action: PayloadAction<TypingIndicator>
        ) {
            state.typingIndicators = removeAll(
                state.typingIndicators,
                (x) => x.userId == action.payload.userId
            );
        },
        textMessageFailed(state, action: PayloadAction<FailedTextMessage>) {
            state.messages.push(action.payload);
        },
        receivedJoinChat(state) {
            state.viewState = ViewState.TalkingToRep;
        },
        joinedChatAsCustomer(
            state,
            action: PayloadAction<JoinChatAsCustomerResponse>
        ) {
            const response = action.payload;
            if (!response || !state.chat) {
                return;
            }

            if (!response.success) {
                state.viewState = ViewState.Error;
                return;
            }

            if (response.state) {
                state.chat.state = response.state;
            }

            switch (state.chat.state) {
                case ChatState.Parked: {
                    state.viewState = ViewState.Parked;
                    break;
                }
                case ChatState.ParkedWithBot: {
                    state.viewState = ViewState.TalkingToBot;
                    break;
                }
                case ChatState.TalkingToBot: {
                    state.viewState = ViewState.TalkingToBot;
                    break;
                }
                case ChatState.TalkingToRep: {
                    state.viewState = ViewState.TalkingToRep;
                    break;
                }
                default:
                    break;
            }

            const firstOperatorJoinedMessage = response.messages?.find(
                (message) =>
                    message.messageType ===
                        PersistedMessageType.UserJoinedChat &&
                    message.clientRole === ClientRole.Operator
            );
            if (firstOperatorJoinedMessage) {
                console.log("Chat joined, operator is already here.", response);
                if (!state.firstOperatorJoinedMessage) {
                    state.firstOperatorJoinedMessage =
                        firstOperatorJoinedMessage as UserJoinedChatMessage;
                }
            } else {
                console.log("Chat joined, operator isn't here yet.", response);
            }

            // Subtract 30 seconds, helps ensure this message stays on top after reconnect and backfilling
            let createdMessageTimestamp: Date | undefined;
            if (response.messages && response.messages.length > 0) {
                createdMessageTimestamp = new Date(
                    response.messages[0].timestamp.getTime() - 1000 * 30
                );
            } else {
                createdMessageTimestamp = new Date(Date.now() - 1000 * 30);
            }

            state.messages = suppressMessagesForCustomer(
                mergeMessages(state.messages, response.messages),
                false,
                state.applicationSettings?.operatorJoinLeftDelaySeconds
            );

            const doesWelcomeMessageExist = state.messages.filter(
                (message) =>
                    message.messageType === ClientMessageType.ChatCreated
            );

            if (doesWelcomeMessageExist.length === 0) {
                state.messages.splice(
                    0,
                    0,
                    new ChatCreatedMessage(
                        state.chat.id,
                        state.chat.customerName,
                        state.chat.customerUserId,
                        createdMessageTimestamp
                    )
                );
            }

            state.chat.directLineToken = response.directLineToken;
            state.chat.lastBotActivityId = response.lastBotActivityId;
            state.chat.lastBotActivityReceivedDate =
                response.lastBotActivityReceivedDate;
        },

        endedChat(state) {
            if (!state.chat) {
                return;
            }

            state.hasChatEnded = true;

            switch (state.chat.state) {
                case ChatState.Parked:
                case ChatState.TalkingToRep: {
                    state.viewState = ViewState.RepFeedback;
                    break;
                }
                case ChatState.ParkedWithBot:
                case ChatState.TalkingToBot: {
                    state.viewState = ViewState.BotFeedback;
                    break;
                }
            }
        },

        chatWasClosed(
            state,
            action: PayloadAction<{
                closeReason: CloseReason;
            }>
        ) {
            transitionViewOnChatUnjoinable(state, {
                isBot:
                    state.chat?.state === ChatState.TalkingToBot ||
                    state.chat?.state === ChatState.ParkedWithBot,
                isTimeout: false,
                ...action.payload,
            });
        },

        viewStateChanged(state, action: PayloadAction<ViewState>) {
            state.viewState = action.payload;
        },

        chatTimedOut(state, action: PayloadAction<{ isBot: boolean }>) {
            transitionViewOnChatUnjoinable(state, {
                isTimeout: true,
                ...action.payload,
            });
        },

        chatStateChanged(state, action: PayloadAction<ChatState>) {
            if (state.chat?.state) {
                state.chat.state = action.payload;
            }
        },

        viewAndChatStateChanged(
            state,
            action: PayloadAction<{
                viewState: ViewState;
                chatState: ChatState;
            }>
        ) {
            if (!state.chat) {
                return;
            }
            state.viewState = action.payload.viewState;
            state.chat.state = action.payload.chatState;
        },

        rejoinChatFailed(state) {
            state.viewState = ViewState.Error;
        },

        leaveMessageTimedOut(
            state,
            action: PayloadAction<UserLeftChatMessage>
        ) {
            //this gets hit after the timeout logic for operator leave messages, so don't do another round of cleanup
            state.messages = mergeMessages(state.messages, [action.payload]);
        },

        applicationSettingsGathered(
            state,
            action: PayloadAction<CustomerApplicationSettings>
        ) {
            state.applicationSettings = action.payload;
        },

        retrievedFeedbackReasons(
            state,
            action: PayloadAction<FeedbackReasonsModel>
        ) {
            state.botChatNotHelpfulReasons =
                action.payload.botChatNotHelpfulReasons.filter(
                    (reason) => reason.shouldDisplay
                );

            state.liveChatNotHelpfulReasons =
                action.payload.liveChatNotHelpfulReasons.filter(
                    (reason) => reason.shouldDisplay
                );
        },

        connectivityMessageDisplayed(state) {
            if (state.chat && state.chatUser && state.messages.length > 0) {
                const mostRecentMessage =
                    state.messages[state.messages.length - 1];

                if (
                    mostRecentMessage.messageType !=
                    ClientMessageType.Reconnecting
                ) {
                    state.messages.push(
                        new ReconnectingMessage(
                            state.chat.id,
                            state.chatUser.name,
                            state.chatUser.userId,
                            ClientRole.Customer
                        )
                    );
                }
            }
        },

        connectivityMessageRemoved(state) {
            if (state.chat && state.chatUser && state.messages.length > 0) {
                const mostRecentMessageIndex = state.messages.length - 1;

                if (
                    state.messages[mostRecentMessageIndex].messageType ===
                    ClientMessageType.Reconnecting
                ) {
                    state.messages.splice(mostRecentMessageIndex, 1);
                }
            }
        },

        operatorsOnlineChanged(state, action: PayloadAction<boolean>) {
            if (state.operatorsOnline !== action.payload) {
                state.operatorsOnline = action.payload;
            }
        },

        chatReceived(
            state,
            action: PayloadAction<CreateChatResponse | OpenChatResponse>
        ) {
            const response = action.payload;
            state.chat = new CustomerChatModel(response);

            state.chatUser = {
                userId: response.customerUserId,
                name: response.customerName,
                email: response.email,
            };
        },

        updateMessageJsonData(
            state,
            action: PayloadAction<UpdateMessageJsonData>
        ) {
            const message = state.messages.find(
                (x) =>
                    x.id === action.payload.messageId &&
                    x.chatId === action.payload.chatId
            ) as CustomActivityMessage;
            if (message) {
                message.jsonData = action.payload.jsonData;
            }
        },

        updateIsScrolledToBottom(state, action: PayloadAction<boolean>) {
            if (
                action.payload &&
                state.unreadMessageCountWhileScrolledUp != 0
            ) {
                state.unreadMessageCountWhileScrolledUp = 0;
            }
            state.isScrolledToBottom = action.payload;
        },

        windowVisibilityChanged(state, action: PayloadAction<boolean>) {
            state.isWindowVisible = action.payload;
            if (action.payload) {
                state.newMessagesReceivedWhileNotVisible = 0;
            }
        },
    },
});

function transitionViewOnChatUnjoinable(
    state: Draft<CustomerState>,
    payload: { isBot: boolean; isTimeout: boolean; closeReason?: CloseReason }
): void {
    if (!state.chat) {
        return;
    }

    state.hasChatEnded = true;
    state.chat.lastJoinableDate = new Date();

    if (payload.isTimeout) {
        state.isFeedbackTimeoutBannerVisible = true;
        state.viewState = payload.isBot
            ? ViewState.BotFeedback
            : ViewState.RepFeedback;
        return;
    }

    switch (payload.closeReason) {
        case CloseReason.RepClosed:
            state.viewState = ViewState.RepFeedback;
            break;
        default:
            state.viewState = ViewState.PreviousChatClosed;
            break;
    }
}

export const {
    onChatHubConnected,
    onChatHubClosed,
    onChatHubReconnecting,
    onChatHubReconnected,
    receivedMessage,
    receivedSmartIncludeMessage,
    receivedUserJoinedChatMessage,
    receivedUserLeftChatMessage,
    receivedMessageToDelete,
    textMessageFailed,
    joinedChatAsCustomer,
    receivedJoinChat,
    chatWasClosed,
    endedChat,
    viewStateChanged,
    chatStateChanged,
    viewAndChatStateChanged,
    rejoinChatFailed,
    leaveMessageTimedOut,
    applicationSettingsGathered,
    retrievedFeedbackReasons,
    connectivityMessageDisplayed,
    connectivityMessageRemoved,
    operatorsOnlineChanged,
    chatReceived,
    receivedUserIsTyping,
    receiveUserStoppedTyping,
    updateIsScrolledToBottom,
    windowVisibilityChanged,
    chatTimedOut,
    updateMessageJsonData,
    clearChatBanner,
    showChatBanner,
} = customerAppSlice.actions;

export const selectSecondsSinceLastJoinable = (state: RootState): number => {
    const lastJoinableDate = state.customerApp.chat?.lastJoinableDate;
    return Math.max(
        lastJoinableDate ? (Date.now() - lastJoinableDate.getTime()) / 1000 : 0,
        0
    );
};

export default customerAppSlice.reducer;
