import {
    closeChatHubConnection,
    setChatHubClosedHandler,
    setChatHubConnectedHandler,
    setChatHubEventHandler,
    setChatHubReconnectedHandler,
    setChatHubReconnectingHandler,
    startChatHub,
} from "@Src/hubs/chat/ChatHub";
import { ChatHubEvents } from "@Src/hubs/chat/ChatHubEvents";
import { ChatClosedModel } from "@Src/hubs/chat/dtos/ChatClosedModel";
import { BotTextMessage } from "@Src/hubs/chat/dtos/BotTextMessage";
import { ImageMessage } from "@Src/hubs/chat/dtos/ImageMessage";
import { JoinChatRequest } from "@Src/hubs/chat/dtos/JoinChatRequest";
import { TextMessage } from "@Src/hubs/chat/dtos/TextMessage";
import { UserJoinedChatMessage } from "@Src/hubs/chat/dtos/UserJoinedChatMessage";
import { UserLeftChatMessage } from "@Src/hubs/chat/dtos/UserLeftChatMessage";
import { MessageToDeleteFromChat } from "@Src/hubs/chat/dtos/MessageToDeleteFromChat";
import {
    onChatHubConnected,
    onChatHubClosed,
    onChatHubReconnecting,
    onChatHubReconnected,
    receivedMessage,
    receivedUserJoinedChatMessage,
    receivedUserLeftChatMessage,
    receivedMessageToDelete,
    joinedChatAsCustomer,
    rejoinChatFailed,
    receivedSmartIncludeMessage,
    chatWasClosed,
    viewStateChanged,
    viewAndChatStateChanged,
    receivedUserIsTyping,
    receiveUserStoppedTyping,
    receivedJoinChat,
    chatTimedOut,
    updateMessageJsonData,
    operatorsOnlineChanged,
    showChatBanner,
    clearChatBanner,
} from "./customerAppSlice";
import { store } from "./store";
import {
    onNewOperatorJoinMessage as shouldReceiveJoinChatMessage,
    onNewOperatorLeftMessage,
} from "@Src/services/customerApp/OperatorLeftDelayService";
import { ClientRole } from "@Src/hubs/chat/dtos/ClientRole";
import { CustomerJoinFailureType } from "@Models/chat/CustomerJoinFailureType";
import { clearHeartbeatTickInterval } from "@Utilities/Heartbeat";
import { ViewState } from "./viewState";
import { getInstance } from "@Src/services/shared/DeployService";
import { SmartIncludeMessage } from "@Src/hubs/chat/dtos/SmartIncludeMessage";
import { JoinChatAsCustomerResponse } from "@Api/dtos/chat/JoinChatAsCustomerResponse";
import { ChatState } from "@Models/chat/ChatState";
import { CustomActivityMessage } from "@Src/hubs/chat/dtos/CustomActivityMessage";
import { UpdateMessageJsonData } from "@Api/dtos/chat/UpdateMessageJsonData";
import { PersistedMessageType } from "@Src/hubs/chat/dtos/PersistedMessageType";
import { CustomerChatMessageEnum } from "./CustomerChatBanner";
import { CustomerChatModel } from "@Models/chat/CustomerChatModel";

const cantRejoinMessageBase = "Can't rejoin chat,";

const hubHandlerKey = "hub";

setChatHubClosedHandler(hubHandlerKey, () => {
    clearHeartbeatTickInterval();
    store.dispatch(onChatHubClosed());
});

setChatHubReconnectingHandler(hubHandlerKey, () => {
    store.dispatch(onChatHubReconnecting());
});

setChatHubReconnectedHandler((hubConnectionId: string | null) => {
    const { customerApp } = store.getState();
    const { chat, chatApi } = customerApp;

    if (!chat) {
        return;
    }

    console.log("Rejoining chat:", chat.id);

    const joinChatRequest: JoinChatRequest = {
        connectionId: hubConnectionId as string,
    };

    chatApi
        .joinChatAsCustomer(joinChatRequest)
        .then(async (joinResponse) => {
            switch (joinResponse.failureType) {
                case CustomerJoinFailureType.ChatClosed:
                    console.warn(`${cantRejoinMessageBase} was closed`);
                    store.dispatch(
                        viewAndChatStateChanged({
                            viewState: ViewState.PreviousChatClosed,
                            chatState: joinResponse.state,
                        })
                    );
                    break;
                case CustomerJoinFailureType.UnexpectedState:
                    console.error(
                        `${cantRejoinMessageBase} data was in an unexpected state`
                    );
                    store.dispatch(rejoinChatFailed());
                    break;
                case CustomerJoinFailureType.UnrecoverableFailure:
                    console.error(
                        `${cantRejoinMessageBase} unrecoverable failure on server occurred`
                    );
                    store.dispatch(rejoinChatFailed());
                    break;
                case CustomerJoinFailureType.CustomerTimedOut:
                    console.error(
                        `${cantRejoinMessageBase} chat was timed out`
                    );
                    if (shouldSendToWelcome(joinResponse)) {
                        await chatApi.resetSession();
                        window.location.reload();
                        return;
                    }
                    store.dispatch(
                        viewStateChanged(
                            chat.state === ChatState.TalkingToRep ||
                                chat.state === ChatState.Parked
                                ? ViewState.RepFeedback
                                : ViewState.BotFeedback
                        )
                    );
                    store.dispatch(rejoinChatFailed());
                    break;
                default:
                    if (joinResponse.success) {
                        store.dispatch(onChatHubReconnected(joinResponse));
                    } else {
                        console.error(
                            `${cantRejoinMessageBase} unexpected failure type`
                        );
                        store.dispatch(rejoinChatFailed());
                    }
                    break;
            }
        })
        .catch(() => {
            console.error(`${cantRejoinMessageBase} unexpected API error`);
            store.dispatch(rejoinChatFailed());
        });
});

function shouldSendToWelcome(
    joinResponse: JoinChatAsCustomerResponse
): boolean {
    if (
        joinResponse.failureType === CustomerJoinFailureType.CustomerTimedOut &&
        joinResponse.lastJoinableDate
    ) {
        const secondsSinceTimedOut =
            Date.now() - joinResponse.lastJoinableDate.getTime() / 1000;

        const oneHourInSeconds = 60 * 60;

        return secondsSinceTimedOut > oneHourInSeconds;
    }
    return false;
}

const handleBanner = (
    chat: CustomerChatModel | undefined,
    joinResponse: JoinChatAsCustomerResponse,
    areOperatorsOnline: boolean
): void => {
    if (chat?.isPlatinum) {
        if (
            // Check if the customer has sent a message
            !joinResponse.messages.find(
                (x) =>
                    x.senderName !== "Pepper" &&
                    x.clientRole !== ClientRole.Operator &&
                    x.messageType !== PersistedMessageType.UserJoinedChat &&
                    x.messageType !== PersistedMessageType.UserLeftChat
            )
        ) {
            store.dispatch(
                showChatBanner({
                    chatBannerMessage:
                        CustomerChatMessageEnum.PLATINUM_BOT_CHAT_BANNER_MESSAGE,
                    active: true,
                })
            );
        } else if (
            chat?.state == ChatState.TalkingToBot &&
            areOperatorsOnline
        ) {
            // Only show this message if the customer has sent a message and is talking to the bot since once you are talking to a rep we don't want to transfer to a rep
            store.dispatch(
                showChatBanner({
                    chatBannerMessage:
                        CustomerChatMessageEnum.PLATINUM_CHAT_WITH_REP_BANNER_MESSAGE,
                    active: true,
                })
            );
        } else store.dispatch(clearChatBanner());
    }
};

setChatHubConnectedHandler(hubHandlerKey, async (hubConnectionId) => {
    const { customerApp } = store.getState();
    const { chat, chatApi } = customerApp;

    if (!hubConnectionId) {
        store.dispatch(viewStateChanged(ViewState.Error));
        return;
    }

    store.dispatch(onChatHubConnected(hubConnectionId));

    console.log("Joining chat:", chat);

    const joinChatRequest: JoinChatRequest = {
        connectionId: hubConnectionId,
    };

    const joinResponse = await chatApi.joinChatAsCustomer(joinChatRequest);

    if (shouldSendToWelcome(joinResponse)) {
        await chatApi.resetSession();
        window.location.reload();
        return;
    }

    const areOperatorsOnline = await chatApi.getOperatorsOnlineStatus();

    handleBanner(chat, joinResponse, areOperatorsOnline);

    store.dispatch(joinedChatAsCustomer(joinResponse));

    store.dispatch(operatorsOnlineChanged(areOperatorsOnline));
});

setChatHubEventHandler({
    event: ChatHubEvents.ReceiveTextMessage,
    handler(message) {
        const textMessage: TextMessage = new TextMessage(message);
        store.dispatch(receivedMessage(textMessage));
    },
});

setChatHubEventHandler({
    event: ChatHubEvents.ReceiveBotTextMessage,
    handler(message) {
        const botTextMessage: BotTextMessage = new BotTextMessage(message);
        store.dispatch(receivedMessage(botTextMessage));
    },
});

setChatHubEventHandler({
    event: ChatHubEvents.ReceiveCustomActivityMessage,
    handler(message) {
        const customActivityMessage: CustomActivityMessage =
            new CustomActivityMessage(message);
        store.dispatch(receivedMessage(customActivityMessage));
    },
});

setChatHubEventHandler({
    event: ChatHubEvents.UpdateMessageJsonData,
    handler(payload: UpdateMessageJsonData) {
        store.dispatch(updateMessageJsonData(payload));
    },
});

setChatHubEventHandler({
    event: ChatHubEvents.ReceiveOperatorsOnlineChangeMessage,
    handler(isOnline: boolean) {
        store.dispatch(operatorsOnlineChanged(isOnline));
    },
});

setChatHubEventHandler({
    event: ChatHubEvents.ReceiveImageMessage,
    handler(message) {
        const imageMessage: ImageMessage = new ImageMessage(message);
        store.dispatch(receivedMessage(imageMessage));
    },
});

setChatHubEventHandler({
    event: ChatHubEvents.ReceiveUserJoinedChatMessage,
    handler(message) {
        const joinedChatMessage: UserJoinedChatMessage =
            new UserJoinedChatMessage(message);
        if (shouldReceiveJoinChatMessage(joinedChatMessage)) {
            store.dispatch(receivedUserJoinedChatMessage(joinedChatMessage));
        }
    },
});

setChatHubEventHandler({
    event: ChatHubEvents.ReceiveUserLeftChatMessage,
    handler(message) {
        const leftChatMessage: UserLeftChatMessage = new UserLeftChatMessage(
            message
        );
        if (leftChatMessage.clientRole === ClientRole.Operator) {
            onNewOperatorLeftMessage(leftChatMessage);
        }
        store.dispatch(receivedUserLeftChatMessage(leftChatMessage));
    },
});

setChatHubEventHandler({
    event: ChatHubEvents.ReceiveMessageToDelete,
    handler(data) {
        const messageToDelete: MessageToDeleteFromChat =
            new MessageToDeleteFromChat(data.chatId, data.messageId);
        store.dispatch(receivedMessageToDelete(messageToDelete));
    },
});

setChatHubEventHandler({
    event: ChatHubEvents.ReceiveJoinChat,
    handler() {
        store.dispatch(receivedJoinChat());
    },
});

setChatHubEventHandler({
    event: ChatHubEvents.ReceiveOperatorOnlyInformationalMessage,
    handler() {
        // do nothing with this event in the customer app
    },
});

setChatHubEventHandler({
    event: ChatHubEvents.ReceiveChatWasClosed,
    handler(data: ChatClosedModel) {
        store.dispatch(chatWasClosed({ closeReason: data.reason }));
    },
});

setChatHubEventHandler({
    event: ChatHubEvents.ReceiveSmartIncludeMessage,
    handler(message) {
        const smartIncludeMessage = new SmartIncludeMessage(message);
        store.dispatch(receivedSmartIncludeMessage(smartIncludeMessage));
    },
});

setChatHubEventHandler({
    event: ChatHubEvents.ReceiveUserTimedOutMessage,
    handler(message) {
        store.dispatch(chatTimedOut(message));
    },
});

setChatHubEventHandler({
    event: ChatHubEvents.ReceiveMyServerDeployStarted,
    handler() {
        getInstance().onMyServerDeployStarted(
            closeChatHubConnection,
            () =>
                startChatHub(true).then((hubConnectionId) =>
                    hubConnectionId ? true : false
                ),
            () => {
                store.dispatch(onChatHubReconnecting());
            }
        );
    },
});

setChatHubEventHandler({
    event: ChatHubEvents.ReceiveTypingIndicator,
    handler(message) {
        store.dispatch(
            receivedUserIsTyping({
                chatId: message.chatId,
                name: message.name,
                isFromCustomer: message.isFromCustomer,
                userId: message.userId,
            })
        );
    },
});

setChatHubEventHandler({
    event: ChatHubEvents.ReceiveStopTypingIndicator,
    handler(message) {
        store.dispatch(
            receiveUserStoppedTyping({
                chatId: message.chatId,
                name: message.name,
                isFromCustomer: message.isFromCustomer,
                userId: message.userId,
            })
        );
    },
});
