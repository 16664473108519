import "./BotConversationView.less";
import { ChatConnectionState } from "@Src/hubs/chat/ChatConnectionState";
import { WebChatCustomStyles } from "./WebChatCustomStyles";
import {
    createDirectLine,
    createStore,
    Components,
} from "botframework-webchat";
import { DirectLine, Activity, EventActivity } from "botframework-directlinejs";
const {
    AdaptiveCardContent,
    TextContent,
    BasicWebChat,
    Composer,
    SuggestedActions,
    Timestamp,
} = Components;
import { useAppDispatch, useAppSelector } from "@Components/CustomerApp/hooks";
import { ActionType } from "@Models/chatBot/ActionType";
import { ActivityName } from "@Models/chatBot/ActivityName";
import { ChatState } from "@Models/chat/ChatState";
import { ActivityType } from "@Models/chatBot/ActivityType";
import { useEffect, useMemo, useState, useCallback, useRef } from "react";
import { convertActivityToMessages } from "./BotActivityConverter";
import { ActivityRole } from "@Models/chatBot/ActivityRole";
import { getQueryParam } from "@Utilities/QueryStringHelper";
import { QueryParams } from "@Utilities/QueryParams";
import { WssIssues } from "../issues";
import {
    chatStateChanged,
    viewStateChanged,
    viewAndChatStateChanged,
    clearChatBanner,
    showChatBanner,
} from "../customerAppSlice";
import ActivityMiddleware from "botframework-webchat-api/lib/types/ActivityMiddleware";
import {
    AttachmentMiddleware,
    AttachmentForScreenReaderMiddleware,
    ActivityStatusMiddleware,
} from "botframework-webchat-api";
import { AttachmentType } from "@Models/chatBot/AttachmentType";
import { ViewState } from "../viewState";
import { clearTimeout, setTimeout } from "timers";
import ErrorBoundaryForWebChat from "./ErrorBoundaryForWebChat";
import { FailureType } from "@Models/customer/FailureType";
import TrackOrderTextBubble from "./CustomActivities/TrackOrderTextBubble";
import TrackOrderTextBubbleForScreenReader from "./CustomActivities/ScreenReaderVersions/TrackOrderTextBubbleForScreenReader";
import BotFeedbackDecorator from "./BotFeedbackDecorator";
import { applicationId } from "@Src/tenantConfiguration/CustomerTenantConfiguration";
import { BotMessageBar } from "./BotMessageBar";
import { PersistedMessageType } from "@Src/hubs/chat/dtos/PersistedMessageType";
import { TextMessage } from "@Src/hubs/chat/dtos/TextMessage";
import { setTriggerCheckerDispatch } from "./WipActivityTriggers";
import TaxFormBubble from "./CustomActivities/TaxFormBubble";
import BotHelpButtonDecorator from "./BotHelpButtonDecorator";
import CustomerItemRefundReship from "./CustomActivities/ItemIssues/RefundReship/CustomerItemRefundReship";
import { RecordChatEventRequest } from "@Src/hubs/chat/dtos/RecordChatEventRequest";
import { MessageBackValueProperties } from "@Models/chatBot/MessageBackValueProperties";
import CustomerItemReturns from "./CustomActivities/ItemIssues/Returns/CustomerItemReturns";
import CustomerItemFoodQualityIssue from "./CustomActivities/ItemIssues/FoodQualityIssue/CustomerItemFoodQualityIssue";
import { BuildContentTypeAttachmentProps } from "./BuildContentTypeAttachmentProps";
import { InsertOperatorOnlyInformationalMessageRequest } from "@Src/hubs/chat/dtos/insertOperatorOnlyInformationalMessageRequest";
import { ProductBotChannelData } from "@Models/chatBot/ProductBotChannelData";
import { CustomerChatMessageEnum } from "../CustomerChatBanner";

const encryptedUserIndexParam = getQueryParam(QueryParams.userIndex);

interface WebChatMiddlewareContext {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    handle?: (dispatch: any, action: any) => boolean;
    dispatch?: (args: any) => void;
    /* eslint-enable @typescript-eslint/no-explicit-any */
}

const middlewareContext = {} as WebChatMiddlewareContext;

export interface BotConversationViewProps {
    sendTypingIndicator(chatId: string): void;
    sendStopTypingIndicator(chatId: string): void;
    typingNotificationTimeoutTimeInSeconds: number;
}

const BotConversationView = ({
    sendTypingIndicator,
    sendStopTypingIndicator,
    typingNotificationTimeoutTimeInSeconds,
}: BotConversationViewProps): React.JSX.Element => {
    const dispatch = useAppDispatch();
    const chat = useAppSelector((state) => state.customerApp.chat);
    const chatState = useAppSelector((state) => state.customerApp.chat?.state);
    const messageApi = useAppSelector((state) => state.customerApp.messageApi);
    const chatApi = useAppSelector((state) => state.customerApp.chatApi);
    const isReconnectingToHub =
        useAppSelector((state) => state.customerApp.chatHubConnectionState) ===
        ChatConnectionState.Reconnecting;
    const hasEndedChat = useAppSelector(
        (state) => state.customerApp.hasChatEnded
    );
    const botMessageReplyTimeoutInSeconds = useAppSelector(
        (state) =>
            state.customerApp.applicationSettings?.chatBotSettings
                ?.botMessageReplyTimeoutInSeconds ?? 5
    );
    const botMessageReplyNumRetries = useAppSelector(
        (state) =>
            state.customerApp.applicationSettings?.chatBotSettings
                ?.botMessageReplyNumRetries ?? 2
    );
    const customerReassuranceSecondsToBegin = useAppSelector(
        (state) =>
            state.customerApp.applicationSettings?.chatBotSettings
                ?.customerReassuranceSecondsToBegin ?? 60
    );
    const customerReassuranceIntervalInSeconds = useAppSelector(
        (state) =>
            state.customerApp.applicationSettings?.chatBotSettings
                ?.customerReassuranceIntervalInSeconds ?? 180
    );
    const noResponseTimeoutInSeconds = useAppSelector(
        (state) =>
            state.customerApp.applicationSettings?.chatBotSettings
                ?.noResponseTimeoutInSeconds ?? 10000
    );
    const isMobile = useAppSelector(
        (state) => state.customerApp?.chat?.isMobile ?? false
    );
    const operatorsOnline = useAppSelector(
        (state) => state.customerApp.operatorsOnline
    );
    const viewState = useAppSelector((state) => state.customerApp.viewState);
    const [isWebChatInitialized, setIsWebChatInitialized] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isSoftDisabled, setIsSoftDisabled] = useState(false);
    const [softDisabledErrorMessage, setSoftDisabledErrorMessage] =
        useState("");

    const replacedActivities = useRef<{
        [activityId: string]: { message: string };
    }>({});

    const deletedActivities = useRef<{
        [activityId: string]: boolean;
    }>({});

    const [isReassuringWhileParked, setIsReassuringWhileParked] =
        useState<boolean>(false);

    const initialResponseTimeout = useRef<NodeJS.Timeout | null>(null);

    const qnaKey = WssIssues.find(
        (issue) => issue.value === chat?.issueInformation?.issue
    )?.qnaKey;

    const appDispatch = useAppDispatch();

    const [isTimedOutNoInitialBotResponse, setIsTimedOutNoInitialBotResponse] =
        useState(false);

    const hasBegunTransferToRep = useRef(false);

    const botMessageReplyTimeout = useRef<NodeJS.Timeout | null>(null);

    const botMessageReplyRetryAttempt = useRef<number>(0);

    const receivedBotReplies = useRef<{
        [messageId: string]: boolean;
    }>({});

    const [
        botMessageReplyTimeoutComplete,
        setNoBotMessageReplyTimeoutComplete,
    ] = useState(false);

    const [directLineToken, setDirectLineToken] = useState("");

    const helpButtonText = "I need help with something else";

    const haveNotPlacedOrderButtonText = "I have not placed my order yet";

    const directLine = useMemo(
        (): DirectLine | undefined =>
            directLineToken
                ? createDirectLine({
                      token: directLineToken,
                  })
                : undefined,
        [directLineToken]
    );

    const shouldStartTimer = useCallback(
        (): boolean =>
            isWebChatInitialized && viewState === ViewState.TalkingToBot,
        [isWebChatInitialized, viewState]
    );

    const botMessageReplyTimeoutSetter = useCallback((): void => {
        if (botMessageReplyTimeout.current) {
            clearTimeout(botMessageReplyTimeout.current);
        }
        botMessageReplyTimeout.current = setTimeout(function () {
            setNoBotMessageReplyTimeoutComplete(true);
        }, 1000 * botMessageReplyTimeoutInSeconds);
    }, [botMessageReplyTimeoutInSeconds]);

    const sendHelpButtonText = useCallback((): void => {
        if (middlewareContext.dispatch) {
            middlewareContext.dispatch({
                type: "WEB_CHAT/SEND_MESSAGE",
                payload: { text: helpButtonText },
            });
        }
    }, []);

    const sendHaveNotPlacedOrderButtonText = useCallback((): void => {
        if (middlewareContext.dispatch) {
            middlewareContext.dispatch({
                type: "WEB_CHAT/SEND_MESSAGE",
                payload: { text: haveNotPlacedOrderButtonText },
            });
        }
    }, []);

    const onChatWithSpecialistClick = useCallback((): void => {
        // Record button clicked
        const recordRequest: RecordChatEventRequest = {
            messageType:
                PersistedMessageType.UserSelectedNotHelpfulAnswerChatWithSpecialist,
        };
        chatApi.recordChatEvent(recordRequest).then(() => {
            // Send message to bot
            if (middlewareContext.dispatch) {
                middlewareContext.dispatch({
                    type: ActionType.WebChat.SendMessageBack,
                    payload: {
                        text: "Chat with Specialist",
                        displayText: "Chat with Specialist",
                        value: {
                            [MessageBackValueProperties.Keys.SkipConfirmation]:
                                MessageBackValueProperties.Values
                                    .SkipConfirmationTrue,
                        },
                    },
                });
            }
        });
    }, [chatApi]);

    const onFeedbackChange = useCallback(
        (isHelpful: boolean, productBotInteractionKey?: string): void => {
            // As of now only the product bot feedback is recorded on Chatbot side instantly
            // Qna feedback is recorded in the SB database when a chat is closed
            if (middlewareContext.dispatch && productBotInteractionKey) {
                middlewareContext.dispatch({
                    type: ActionType.WebChat.SendEvent,
                    payload: {
                        name: ActivityName.SendProductBotAnswerFeedback,
                        value: {
                            IsHelpful: isHelpful,
                            ProductBotInteractionKey: productBotInteractionKey,
                        },
                    },
                });
            }
        },
        []
    );

    useEffect(() => {
        if (isWebChatInitialized) {
            if (isReconnectingToHub) {
                setIsDisabled(true);
            } else {
                setIsDisabled(false);
            }
        }
    }, [isReconnectingToHub, isWebChatInitialized]);

    useEffect(() => {
        const hyperlinks = document.querySelectorAll(
            ".bot-conversation-view a"
        );
        if (hyperlinks.length > 0) {
            hyperlinks.forEach((hyperlink) => {
                hyperlink.removeAttribute("rel");
            });
        }
    });

    const haveBotSendInQueueMessage = useCallback((): void => {
        if (chatState == ChatState.ParkedWithBot) {
            if (chat?.id) {
                chatApi.getPlaceInQueue(chat.id).then((positionInLine) => {
                    if (positionInLine && middlewareContext.dispatch) {
                        middlewareContext.dispatch({
                            type: ActionType.WebChat.SendEvent,
                            payload: {
                                name: ActivityName.ReassureInQueue,
                                value: {
                                    CustomerPositionInLine: positionInLine,
                                },
                            },
                        });
                    }
                });
            } else {
                appDispatch(viewStateChanged(ViewState.Error));
            }
        } else {
            setIsReassuringWhileParked(false);
        }
    }, [chatState, setIsReassuringWhileParked, chat?.id, chatApi, appDispatch]);

    const haveBotSendNoNavigateMessage = useCallback((): void => {
        if (chatState == ChatState.ParkedWithBot) {
            if (middlewareContext.dispatch) {
                middlewareContext.dispatch({
                    type: ActionType.WebChat.SendEvent,
                    payload: {
                        name: ActivityName.DoNotNavigateAway,
                    },
                });
            }
        }
    }, [chatState]);

    const fallbackToRep = useCallback(
        (chatId: string | undefined, failureType: FailureType): void => {
            if (!operatorsOnline) {
                appDispatch(viewStateChanged(ViewState.ChatUnavailable));
                return;
            }
            if (hasBegunTransferToRep.current) {
                return;
            }

            hasBegunTransferToRep.current = true;

            if (chatId) {
                chatApi
                    .changeFromBotToRep({
                        allowLivePark: true,
                        wasAfterFeedback: false,
                        failureType: failureType,
                    })
                    .then((chatState: ChatState) => {
                        let viewState = ViewState.Error;
                        if (chatState == ChatState.Parked) {
                            viewState = ViewState.Parked;
                        } else if (chatState == ChatState.TalkingToRep) {
                            viewState = ViewState.TalkingToRep;
                        }
                        appDispatch(
                            viewAndChatStateChanged({
                                viewState: viewState,
                                chatState: chatState,
                            })
                        );
                    })
                    .catch(() => {
                        appDispatch(viewStateChanged(ViewState.Error));
                    });
            } else {
                appDispatch(viewStateChanged(ViewState.Error));
            }
        },
        [appDispatch, chatApi, operatorsOnline]
    );

    useEffect(() => {
        if (isReassuringWhileParked) {
            let reassureInterval: NodeJS.Timer | undefined;
            const firstReassuranceTimeout = setTimeout(function () {
                haveBotSendInQueueMessage();
                reassureInterval = setInterval(function () {
                    haveBotSendInQueueMessage();
                }, 1000 * customerReassuranceIntervalInSeconds);
            }, 1000 * customerReassuranceSecondsToBegin);
            let noNavigateAwayTimeout: NodeJS.Timeout | undefined;
            if (isMobile) {
                noNavigateAwayTimeout = setTimeout(function () {
                    haveBotSendNoNavigateMessage();
                }, 15000);
            }

            return (): void => {
                clearTimeout(firstReassuranceTimeout);
                if (reassureInterval) {
                    clearInterval(reassureInterval);
                }
                if (noNavigateAwayTimeout) {
                    clearTimeout(noNavigateAwayTimeout);
                }
            };
        }
    }, [
        isReassuringWhileParked,
        haveBotSendInQueueMessage,
        haveBotSendNoNavigateMessage,
        customerReassuranceSecondsToBegin,
        customerReassuranceIntervalInSeconds,
        isMobile,
    ]);

    useEffect(() => {
        if (botMessageReplyTimeoutComplete) {
            botMessageReplyRetryAttempt.current++;
            setNoBotMessageReplyTimeoutComplete(false);
            middlewareContext.dispatch?.({
                type: ActionType.WebChat.SendEvent,
                payload: {
                    name: ActivityName.Resend,
                    value: {},
                },
            });
            if (
                botMessageReplyRetryAttempt.current <= botMessageReplyNumRetries
            ) {
                botMessageReplyTimeoutSetter();
            } else {
                botMessageReplyRetryAttempt.current = 0;
                if (botMessageReplyTimeout.current) {
                    clearTimeout(botMessageReplyTimeout.current);
                }
            }
        }
    }, [
        botMessageReplyNumRetries,
        botMessageReplyTimeoutSetter,
        botMessageReplyTimeoutComplete,
    ]);

    const handleDirectLineConnectionFulfilled = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (webchatDispatch: any): void => {
            webchatDispatch({
                type: ActionType.WebChat.SendEvent,
                payload: {
                    name: ActivityName.JoinChat,
                    value: {
                        Name: chat?.customerName,
                        QnAMetadataKey: qnaKey,
                        EncryptedUID: encryptedUserIndexParam,
                        // Used for userindex decryption, this is always true
                        IsWSSOrLogistics: true,
                        CustomerEmailAddress: chat?.email,
                        SwitchboardChatIdentifier: chat?.id,
                        SwitchboardDboChatId: chat?.dboChatId,
                        SwitchboardApplicationId: applicationId,
                        CompanyType: chat?.companyType,
                        AdditionalParameters: chat?.additionalParameters,
                        IssueInformation: chat?.issueInformation,
                        IsPlatinum: chat?.isPlatinum,
                    },
                },
            });
        },
        [
            chat?.customerName,
            chat?.email,
            chat?.id,
            chat?.dboChatId,
            chat?.companyType,
            chat?.additionalParameters,
            chat?.issueInformation,
            chat?.isPlatinum,
            qnaKey,
        ]
    );

    const getMessageId = (activity: Activity): string | undefined => {
        return activity?.channelData?.MessageId;
    };

    const getQnaNumber = (activity: Activity): number | undefined => {
        return activity?.channelData?.QnaNumber;
    };

    const getKnowledgebaseId = (activity: Activity): number | undefined => {
        return activity?.channelData?.DboKnowledgebaseId;
    };

    const getShouldShowFeedbackButtons = (activity: Activity): boolean => {
        return activity?.channelData?.ShouldShowFeedbackButtons ?? false;
    };

    const getProductBotChannelData = (
        activity: Activity
    ): ProductBotChannelData => {
        return activity?.channelData?.ProductBotAnswer ?? null;
    };

    const getShouldShowHelpButton = (activity: Activity): boolean => {
        return activity?.channelData?.ShouldShowHelpButton ?? false;
    };

    const getShouldShowHaveNotPlacedOrderButton = (
        activity: Activity
    ): boolean => {
        return (
            activity?.channelData?.ShouldShowHaveNotPlacedOrderButton ?? false
        );
    };

    const getIsValidationRetryPrompt = (activity: Activity): boolean => {
        return activity?.channelData?.IsValidationRetryPrompt ?? false;
    };

    const getDate = (activity: Activity): Date => {
        return activity?.timestamp &&
            (typeof activity.timestamp === "string" ||
                (activity.timestamp as unknown) instanceof String ||
                typeof activity.timestamp === "number" ||
                (activity.timestamp as unknown) instanceof Number)
            ? new Date(activity.timestamp)
            : new Date();
    };

    const haveBotSendTextMessage = useCallback((message: string): void => {
        if (middlewareContext.dispatch) {
            middlewareContext.dispatch({
                type: ActionType.WebChat.SendEvent,
                payload: {
                    name: ActivityName.SendTextMessage,
                    value: {
                        Message: message,
                    },
                },
            });
        }
    }, []);

    const createRepWillBeWithShortlyFromBotMessage = useCallback((): void => {
        let message = "";
        message =
            "A representative will be with you shortly. If you have additional information that will help, please type the details here while you are transferred.";
        haveBotSendTextMessage(message);
    }, [haveBotSendTextMessage]);

    const handleMessageActivity = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (activity: Activity): void => {
            const messageId = getMessageId(activity);
            if (messageId) {
                receivedBotReplies.current[messageId] = true;
            }

            if (initialResponseTimeout.current) {
                clearTimeout(initialResponseTimeout.current);
            }

            if (botMessageReplyTimeout.current) {
                clearTimeout(botMessageReplyTimeout.current);
                botMessageReplyTimeout.current = null;
            }

            if (!isWebChatInitialized) {
                setIsWebChatInitialized(true);

                if (isDisabled && !isReconnectingToHub) {
                    setIsDisabled(false);
                }
            }

            // We're ensuring here that if the user refreshes that we don't resend previous messages to the channel
            const activityId: string =
                activity.id ?? activity.channelData?.clientActivityID;

            const timestamp = getDate(activity).getTime();

            const lastKnownActivityTimestamp =
                chat?.lastBotActivityReceivedDate?.getTime() ?? 0;

            const lastKnownActivityId = chat?.lastBotActivityId;

            if (
                timestamp >= lastKnownActivityTimestamp &&
                activityId !== lastKnownActivityId
            ) {
                messageApi.recordBotMessages(
                    convertActivityToMessages(
                        activity,
                        activityId,
                        chat?.id ?? "",
                        chatApi
                    )
                );
            }
        },
        [
            isDisabled,
            setIsDisabled,
            setIsWebChatInitialized,
            messageApi,
            isReconnectingToHub,
            isWebChatInitialized,
            chat?.lastBotActivityId,
            chat?.lastBotActivityReceivedDate,
            chat?.id,
            chatApi,
        ]
    );

    const handleEventActivity = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (activity: EventActivity): void => {
            const messageId = getMessageId(activity);
            if (messageId) {
                receivedBotReplies.current[messageId] = true;
            }

            const activityId = activity.value?.ActivityId;
            const activityDate = getDate(activity);

            switch (activity.name) {
                case ActivityName.InitiateHandoff:
                    if (activity.value.TransferToRepresentative) {
                        const prevChatState = chatState;
                        appDispatch(chatStateChanged(ChatState.ParkedWithBot));

                        if (chat?.id) {
                            setIsReassuringWhileParked(true);
                            chatApi
                                .changeFromBotToRep({
                                    // allowLivePark=false means chat state should stay bot-related even if the bot's handling timed out.
                                    // This is because the bot will still reassure the customer even if a dependent service is causing timeouts.
                                    allowLivePark: false,
                                    wasAfterFeedback: false,
                                    failureType:
                                        activity.value.FailureType ?? undefined,
                                })
                                .then((newChatState: ChatState) => {
                                    if (
                                        newChatState == ChatState.TalkingToRep
                                    ) {
                                        appDispatch(
                                            viewAndChatStateChanged({
                                                viewState:
                                                    ViewState.TalkingToRep,
                                                chatState: newChatState,
                                            })
                                        );
                                    } else {
                                        // Only send the rep will be with you shortly message once
                                        // If state is parked with bot and user refreshes page, do not resend
                                        if (
                                            prevChatState !==
                                            ChatState.ParkedWithBot
                                        ) {
                                            createRepWillBeWithShortlyFromBotMessage();
                                        }
                                    }
                                })
                                .catch(() => {
                                    appDispatch(
                                        viewStateChanged(ViewState.Error)
                                    );
                                });
                        } else {
                            appDispatch(viewStateChanged(ViewState.Error));
                        }
                    }
                    break;
                case ActivityName.ReplaceActivityWithMessage: {
                    if (activityId) {
                        replacedActivities.current[activityId] = {
                            message: activity.value.Message,
                        };
                        messageApi.recordBotMessages([
                            {
                                activityId: activityId,
                                receivedDate: activityDate,
                                text: activity.value.Message,
                                isBotChoice: false,
                                ignoreAnalysis: false,
                            },
                        ]);
                    }
                    break;
                }
                case ActivityName.DeleteActivity:
                    if (activityId) {
                        deletedActivities.current[activityId] = true;
                    }
                    break;
                case ActivityName.SendBeginSoftDisabledMessage:
                    setIsSoftDisabled(true);
                    setSoftDisabledErrorMessage(activity.value.Message);
                    break;
                case ActivityName.SendEndSoftDisabledMessage:
                    setIsSoftDisabled(false);
                    setSoftDisabledErrorMessage("");
                    break;
                case ActivityName.SendOperatorOnlyInformationalMessage: {
                    const operatorOnlyMessageRequest: InsertOperatorOnlyInformationalMessageRequest =
                        {
                            chatId: chat?.id ?? "",
                            senderName: "Pepper",
                            messageType:
                                PersistedMessageType.OperatorOnlyInformationalMessage,
                            activityId: activityId,
                            text: activity.value?.Text,
                        };
                    chatApi?.insertOperatorOnlyInformationalMessage(
                        operatorOnlyMessageRequest
                    );
                    break;
                }
            }
        },
        [
            appDispatch,
            chat?.id,
            chatApi,
            chatState,
            createRepWillBeWithShortlyFromBotMessage,
            messageApi,
        ]
    );

    const handleIncomingActivity = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (action: any): void => {
            const activity = action.payload.activity;
            switch (activity.type) {
                case ActivityType.Message:
                    handleMessageActivity(activity);
                    break;
                case ActivityType.Event:
                    handleEventActivity(activity);
                    break;
            }
        },
        [handleMessageActivity, handleEventActivity]
    );

    const handleBanner = useCallback((): void => {
        if (chat?.isPlatinum) {
            // Show chat with rep banner if platinum chat and operators are online and user is talking to bot else clear banner since user is talking to rep and cannot transfer to a rep
            if (chat?.state == ChatState.TalkingToBot && operatorsOnline) {
                dispatch(
                    showChatBanner({
                        chatBannerMessage:
                            CustomerChatMessageEnum.PLATINUM_CHAT_WITH_REP_BANNER_MESSAGE,
                        active: true,
                    })
                );
            } else dispatch(clearChatBanner());
        }
    }, [chat?.isPlatinum, chat?.state, dispatch, operatorsOnline]);

    useEffect(() => {
        middlewareContext.handle = (
            /* eslint-disable @typescript-eslint/no-explicit-any */
            webchatDispatch: any,
            action: any
            /* eslint-enable @typescript-eslint/no-explicit-any */
        ): boolean => {
            if (viewState !== ViewState.TalkingToBot) {
                return false;
            }

            middlewareContext.dispatch = webchatDispatch;

            setTriggerCheckerDispatch(webchatDispatch);

            const shouldStartTimer = (): boolean =>
                isWebChatInitialized && viewState === ViewState.TalkingToBot;

            if (action.type === ActionType.DirectLine.ConnectFulfilled) {
                handleDirectLineConnectionFulfilled(webchatDispatch);
            } else if (
                action.type === ActionType.WebChat.SendMessage ||
                (action.type === ActionType.WebChat.SendMessageBack &&
                    action.payload?.displayText)
            ) {
                if (shouldStartTimer()) {
                    botMessageReplyTimeoutSetter();
                }

                handleBanner();

                messageApi.sendMessageAsCustomer({
                    text: action.payload.text,
                    messageType: PersistedMessageType.Text,
                } as TextMessage);
            } else if (action.type === ActionType.WebChat.SendEvent) {
                if (shouldStartTimer()) {
                    botMessageReplyTimeoutSetter();
                }
            } else if (
                action.type === ActionType.DirectLine.IncomingActivity &&
                // Messages from you get echoed back over the channel
                action.payload?.activity?.from?.role == ActivityRole.Bot
            ) {
                const messageId = getMessageId(action.payload?.activity);
                const isValidationRetryPrompt = getIsValidationRetryPrompt(
                    action.payload?.activity
                );
                // We disable the de-duping of messages if it's a validation retry prompt.
                if (
                    messageId &&
                    receivedBotReplies.current[messageId] &&
                    !isValidationRetryPrompt
                ) {
                    return false;
                }
                handleIncomingActivity(action);
            } else if (
                action.type === ActionType.DirectLine.ConnectionRejected
            ) {
                fallbackToRep(
                    chat?.id,
                    FailureType.DirectLineConnectionRejected
                );
            }
            return true;
        };
    }, [
        chatState,
        isDisabled,
        messageApi,
        handleDirectLineConnectionFulfilled,
        handleIncomingActivity,
        fallbackToRep,
        chat?.id,
        qnaKey,
        appDispatch,
        chatApi,
        haveBotSendInQueueMessage,
        viewState,
        isWebChatInitialized,
        botMessageReplyTimeoutSetter,
        shouldStartTimer,
        dispatch,
        chat?.isPlatinum,
        handleBanner,
    ]);

    const store = useMemo(
        () =>
            createStore(
                {},
                /* eslint-disable @typescript-eslint/no-explicit-any */
                ({ dispatch }: any) =>
                    (next: any) =>
                    (action: any): any => {
                        if (
                            action.type === "WEB_CHAT/SEND_MESSAGE" &&
                            action.payload.text === "credit card"
                        ) {
                            return;
                        }
                        if (!middlewareContext.handle?.(dispatch, action)) {
                            return;
                        }
                        return next(action);
                    }
                /* eslint-enable @typescript-eslint/no-explicit-any */
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        if (chat?.id && chat?.directLineToken && !directLine)
            try {
                setDirectLineToken(chat.directLineToken);
            } catch {
                fallbackToRep(chat.id, FailureType.CreateDirectLine);
            }
    }, [chat?.id, chat?.directLineToken, fallbackToRep, directLine]);

    // When the chat is ready, and we have a token, begin the timeout waiting for the first message from ChatBot
    useEffect(() => {
        if (
            chat?.id &&
            chat?.directLineToken &&
            // Prevent re-creation of timeout
            !initialResponseTimeout.current
        ) {
            initialResponseTimeout.current = setTimeout(function () {
                setIsTimedOutNoInitialBotResponse(true);
            }, 1000 * noResponseTimeoutInSeconds);
        }
    }, [
        chat?.id,
        chat?.directLineToken,
        noResponseTimeoutInSeconds,
        viewState,
        appDispatch,
        fallbackToRep,
        setIsTimedOutNoInitialBotResponse,
    ]);

    // If we timed out waiting for a message from ChatBot, and we still have WebChat up:
    useEffect(() => {
        if (
            isTimedOutNoInitialBotResponse &&
            // Prevent re-execution of fallback
            initialResponseTimeout.current &&
            chat?.id &&
            viewState === ViewState.TalkingToBot
        ) {
            // It should be completed by this point, but just to be sure, clear it again
            clearTimeout(initialResponseTimeout.current);
            initialResponseTimeout.current = null;
            appDispatch(
                viewAndChatStateChanged({
                    viewState: ViewState.Parked,
                    chatState: ChatState.Parked,
                })
            );
            fallbackToRep(chat?.id, FailureType.BotNoRespondTimeout);
        }
    }, [
        isTimedOutNoInitialBotResponse,
        chat?.id,
        viewState,
        appDispatch,
        fallbackToRep,
    ]);

    const activityMiddleware: ActivityMiddleware = useCallback(
        () =>
            (next) =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (options): any => {
                const { activity } = options;
                if (deletedActivities.current[activity.id as string]) {
                    return null;
                } else {
                    return next(options);
                }
            },
        []
    );

    const buildContentTypeAttachment = useCallback(
        ({
            attachment,
            messageId,
            recentBotMessage,
            isScreenReaderAttachment,
        }: BuildContentTypeAttachmentProps): JSX.Element | undefined | null => {
            switch (attachment.contentType) {
                case AttachmentType.AdaptiveCard:
                    return (
                        <AdaptiveCardContent
                            actionPerformedClassName="card__action--performed"
                            content={attachment.content}
                            // Make sure it can't be interacted with immediately after clicking it
                            disabled={!recentBotMessage}
                        />
                    );
                case AttachmentType.OrderTracking:
                    return isScreenReaderAttachment ? (
                        <TrackOrderTextBubbleForScreenReader
                            data={attachment.content}
                        />
                    ) : (
                        <TrackOrderTextBubble data={attachment.content} />
                    );
                case AttachmentType.TaxTracking:
                    return (
                        <TaxFormBubble
                            id={`tax_status_${
                                isScreenReaderAttachment ? "fsr_" : ""
                            }${messageId}`}
                            taxData={attachment.content}
                            isForScreenReader={isScreenReaderAttachment}
                        />
                    );
                case AttachmentType.ItemRefundReship:
                    return (
                        <CustomerItemRefundReship
                            chatId={chat?.id}
                            orderInfo={attachment.content.itemRefundReship}
                            messageId={messageId}
                            resolutionsInfo={attachment.content.resolutionsInfo}
                            isCanceled={attachment.content.isCanceled}
                            dboChatId={chat?.dboChatId}
                        />
                    );
                case AttachmentType.ItemReturns:
                    return (
                        <CustomerItemReturns
                            chatId={chat?.id}
                            dboChatId={chat?.dboChatId}
                            orderInfo={attachment.content.itemReturns}
                            messageId={messageId}
                            resolutionsInfo={attachment.content.resolutionsInfo}
                            isCanceled={attachment.content.isCanceled}
                            itemNotListed={attachment.content.itemNotListed}
                            validReturnReasons={
                                attachment.content.validReturnReasons
                            }
                            restockingFeePercentage={
                                attachment.content.restockingFeePercentage
                            }
                        />
                    );
                case AttachmentType.FoodQualityIssue:
                    return (
                        <CustomerItemFoodQualityIssue
                            chatId={chat?.id}
                            dboChatId={chat?.dboChatId}
                            orderInfo={attachment.content.itemFoodQualityIssue}
                            messageId={messageId}
                            isCanceled={attachment.content.isCanceled}
                            resolutionsInfo={
                                attachment.content
                                    .itemFoodQualityIssueResolution
                            }
                            eligibleFoodQualityItemIssueNames={
                                attachment.content
                                    .eligibleFoodQualityItemIssueNames
                            }
                        />
                    );
                default:
                    break;
            }

            return undefined;
        },
        [chat?.dboChatId, chat?.id]
    );

    /* eslint-disable @typescript-eslint/no-explicit-any */
    const attachmentMiddleware: AttachmentMiddleware = useCallback(
        () =>
            (next) =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, react/display-name
            (options): any => {
                if (!options) {
                    return next(options);
                }
                const { activity, attachment } = options;

                const state = store.getState();
                const activities = state.activities as any[];
                const messageActivities = activities.filter(
                    (a) => a.type === ActivityType.Message
                );
                const recentBotMessage = messageActivities.pop() === activity;

                const activityTypeActivity = activity as Activity;
                const qnaNumber = getQnaNumber(activityTypeActivity);
                const knowledgebaseId =
                    getKnowledgebaseId(activityTypeActivity);
                const messageId = getMessageId(activityTypeActivity);
                const shouldShowFeedbackButtons =
                    getShouldShowFeedbackButtons(activityTypeActivity);
                const shouldShowHelpButton =
                    getShouldShowHelpButton(activityTypeActivity);
                const shouldShowHaveNotPlacedOrderButton =
                    getShouldShowHaveNotPlacedOrderButton(activityTypeActivity);

                if (shouldShowFeedbackButtons && messageId) {
                    const returnedFunction = next(options);

                    return (
                        <BotFeedbackDecorator
                            messageId={messageId}
                            qnaNumber={qnaNumber}
                            knowledgeBaseId={knowledgebaseId}
                            productBotChannelData={getProductBotChannelData(
                                activityTypeActivity
                            )}
                            onChatWithSpecialistClick={
                                onChatWithSpecialistClick
                            }
                            onFeedbackChange={onFeedbackChange}
                        >
                            {returnedFunction}
                        </BotFeedbackDecorator>
                    );
                }

                if (
                    shouldShowHaveNotPlacedOrderButton ||
                    shouldShowHelpButton
                ) {
                    const returnedFunction = next(options);

                    return (
                        <BotHelpButtonDecorator
                            helpButtonText={helpButtonText}
                            haveNotPlacedOrderButtonText={
                                haveNotPlacedOrderButtonText
                            }
                            onHelpButtonClick={sendHelpButtonText}
                            onHaveNotPlacedOrderButtonClick={
                                sendHaveNotPlacedOrderButtonText
                            }
                            shouldShowHelpButton={shouldShowHelpButton}
                            shouldShowHaveNotPlacedOrderButton={
                                shouldShowHaveNotPlacedOrderButton
                            }
                        >
                            {returnedFunction}
                        </BotHelpButtonDecorator>
                    );
                }

                const replacementData =
                    replacedActivities.current[activity.id as string];
                if (replacementData) {
                    return (
                        <TextContent
                            contentType={TextContentType.PlainText}
                            text={replacementData.message}
                        />
                    );
                }

                const retVal = buildContentTypeAttachment({
                    attachment,
                    messageId: activity.id,
                    recentBotMessage,
                    isScreenReaderAttachment: false,
                });

                if (retVal == undefined) {
                    return next(options);
                }

                return retVal;
            },
        [
            store,
            buildContentTypeAttachment,
            onChatWithSpecialistClick,
            onFeedbackChange,
            sendHelpButtonText,
            sendHaveNotPlacedOrderButtonText,
        ]
    );
    /* eslint-enable @typescript-eslint/no-explicit-any */

    const handleScreenReaderAttachment = (args: any, next: any): any => {
        const [{ attachment }] = args;
        if (!attachment) {
            return next(...args);
        }

        const retVal = buildContentTypeAttachment({
            attachment,
            messageId: args[0].activity.id,
            recentBotMessage: false,
            isScreenReaderAttachment: true,
        });

        if (retVal == undefined) {
            return next(...args);
        }

        return () => retVal;
    };

    /* eslint-disable @typescript-eslint/no-explicit-any */
    const attachmentForScreenReaderMiddleware: AttachmentForScreenReaderMiddleware =
        useCallback(
            () =>
                (next) =>
                (...args): any =>
                    handleScreenReaderAttachment(args, next),
            []
        );
    /* eslint-enable @typescript-eslint/no-explicit-any */

    const statusMiddleware: ActivityStatusMiddleware = useCallback(
        () =>
            (next) =>
            // eslint-disable-next-line react/display-name
            (...args) => {
                const [{ activity, hideTimestamp }] = args;
                if (activity.from.role === "bot" && !hideTimestamp) {
                    return (
                        <>
                            <span className="sender-name">Pepper</span>
                            <Timestamp activity={activity} />
                        </>
                    );
                } else {
                    return next(...args);
                }
            },
        []
    );

    useEffect(() => {
        if (hasEndedChat && initialResponseTimeout.current) {
            clearTimeout(initialResponseTimeout.current);
        }
    }, [hasEndedChat]);

    const getBotConversationView = (): React.JSX.Element => {
        return (
            <>
                {isReconnectingToHub && (
                    <div className="bot-conversation__banner-danger">
                        A connection error has occurred, you will be reconnected
                        automatically.
                        <br />
                        If the problem persists, please try to{" "}
                        <a
                            // href just cosmetic to make screen readers happy
                            href={window.location.href}
                            onClick={(): void => window.location.reload()}
                        >
                            Refresh
                        </a>
                    </div>
                )}
                <ErrorBoundaryForWebChat fallbackToRep={fallbackToRep}>
                    <div className="bot-conversation-view">
                        <Composer
                            directLine={directLine}
                            store={store}
                            disabled={isDisabled}
                            styleOptions={WebChatCustomStyles}
                            attachmentMiddleware={attachmentMiddleware}
                            activityMiddleware={activityMiddleware}
                            attachmentForScreenReaderMiddleware={
                                attachmentForScreenReaderMiddleware
                            }
                            // There seems to be some bug with focii key when using composer vs. full webchat, false
                            // prevents some internal webchat from throwing an error
                            suggestedActionsAccessKey={false}
                            activityStatusMiddleware={statusMiddleware}
                        >
                            <BasicWebChat className="bot-conversation-view__web-chat" />
                            <SuggestedActions />
                            {chat && (
                                <BotMessageBar
                                    chatId={chat.id}
                                    sendTypingIndicator={sendTypingIndicator}
                                    isDisabled={isDisabled}
                                    sendStopTypingIndicator={
                                        sendStopTypingIndicator
                                    }
                                    typingNotificationTimeoutTimeInSeconds={
                                        typingNotificationTimeoutTimeInSeconds
                                    }
                                    isSoftDisabled={isSoftDisabled}
                                    softDisabledErrorMessage={
                                        softDisabledErrorMessage
                                    }
                                />
                            )}
                        </Composer>
                    </div>
                </ErrorBoundaryForWebChat>
            </>
        );
    };

    return (
        <div className="bot-conversation">
            {(chat?.state == ChatState.TalkingToBot ||
                chat?.state == ChatState.ParkedWithBot) &&
                directLine &&
                getBotConversationView()}
        </div>
    );
};

enum TextContentType {
    PlainText = "text/plain",
    Markdown = "text/markdown",
}

export default BotConversationView;
