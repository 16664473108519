import { useAppSelector } from "@Components/OperatorApp/hooks";
import SmartIncludeButton from "../SmartIncludeButton/SmartIncludeButton";

interface SmartZipCodeLookupProps {
    zipCode: string;
    isMineOrOperatorSeeingBot: boolean;
}

const SmartZipCodeLookup = ({
    zipCode,
    isMineOrOperatorSeeingBot,
}: SmartZipCodeLookupProps): JSX.Element => {
    const webstaurantStoreBaseUrl = useAppSelector(
        (state) => state.operatorApp.webstaurantStoreBaseUrl
    );

    return (
        <SmartIncludeButton
            buttonText="Check Shipping Estimate"
            href={`${webstaurantStoreBaseUrl}/shipping_time_details.html`}
            isMineOrOperatorSeeingBot={isMineOrOperatorSeeingBot}
        />
    );
};

export default SmartZipCodeLookup;
