import { InfoCircleTwoTone, TwoToneColor } from "@ant-design/icons";

export const PlatinumBotChatBannerMessage = (): JSX.Element => {
    const twoToneColor: TwoToneColor = ["#cce5f0", "#1890ff"];

    return (
        <>
            <InfoCircleTwoTone
                twoToneColor={twoToneColor}
                className="chat-banner_icon"
                data-testid="customer-chat-banner-icon"
            />
            <div
                className="chat-banner_message"
                data-testid="customer-chat-banner-message"
            >
                <b>Platinum Chat Update:</b> Your experience may look a little
                different - chats now start with Pepper, our automated
                assistant, who can help with orders, account questions, and
                more. You can still chat with the Platinum team at any time,
                Monday - Friday: 8AM - 8PM EST.
            </div>
        </>
    );
};
