import React from "react";

export const screenReaderOnlyCharacterCountSpan = (
    currentLength: number,
    maxLength: number
): React.JSX.Element => {
    return (
        <span className="sr-only" aria-live="polite" aria-atomic="true">
            {currentLength} of {maxLength} characters used
        </span>
    );
};
