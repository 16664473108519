import "./ChatTimedOutView.less";
import { Button, Typography, Space } from "antd";
const { Text } = Typography;
import withSessionReset from "../WithSessionReset/WithSessionReset";
import withChatHubDisconnect from "../WithChatHubDisconnect/WithChatHubDisconnect";
import {
    faqLink,
    helpEmail,
    faqButtonText,
    showHelpEmailButton,
} from "@Src/tenantConfiguration/CustomerTenantConfiguration";
import { customerServiceDeptName } from "@Src/tenantConfiguration/SharedTenantConfiguration";

const ChatTimedOutView = (): JSX.Element => {
    const onStartNewChatClicked = (): void => {
        window.location.reload();
    };

    return (
        <div className="chat-timed-out-view">
            <div className="chat-timed-out-view__content">
                <Text>
                    Sorry, you were timed out due to inactivity.
                    <br />
                    Please refresh the window if you still need assistance or
                    choose one of the options below.
                </Text>
                <Space className="chat-timed-out-view__content__buttons">
                    <Button
                        className="chat-timed-out-view__content__button-outlined"
                        onClick={onStartNewChatClicked}
                    >
                        Refresh
                    </Button>
                    {showHelpEmailButton && (
                        <Button
                            type="primary"
                            href={`mailto:${helpEmail}`}
                            target="_blank"
                        >
                            Email {customerServiceDeptName}
                        </Button>
                    )}
                    {faqLink && (
                        <Button type="primary" href={faqLink} target="_blank">
                            {faqButtonText}
                        </Button>
                    )}
                </Space>
            </div>
        </div>
    );
};

export default withChatHubDisconnect(withSessionReset(ChatTimedOutView));
