import { CreateChatResponse } from "@Api/dtos/chat/CreateChatResponse";
import { OpenChatResponse } from "@Api/dtos/chat/OpenChatResponse";
import { ChatState } from "./ChatState";
import { IssueInformationModel } from "./IssueInformationModel";
import { NoChatNextView } from "./NoChatNextView";

export class CustomerChatModel {
    hasOpenChat: boolean;
    nextView?: NoChatNextView;
    isCustomerTimedOut: boolean;
    lastJoinableDate?: Date;
    id: string;
    customerUserId: string;
    customerName: string;
    email: string;
    company: string;
    phone: string;
    state: ChatState;
    dboChatId: number;
    directLineToken?: string;
    issueInformation: IssueInformationModel;
    lastBotActivityReceivedDate?: Date;
    lastBotActivityId: string;
    isMobile: boolean;
    companyType: string;
    isPlatinum: boolean;
    additionalParameters: Record<string, string>;

    public constructor(chat: CreateChatResponse | OpenChatResponse) {
        this.id = chat.id;
        this.customerUserId = chat.customerUserId;
        this.customerName = chat.customerName;
        this.email = chat.email;
        this.company = chat.company;
        this.phone = chat.phone;
        this.state = chat.state;
        this.dboChatId = chat.dboChatId;
        this.issueInformation = chat.issueInformation;
        this.isMobile = chat.isMobile;
        this.companyType = chat.companyType;
        this.isPlatinum = chat.isPlatinum;
        this.additionalParameters = chat.additionalParameters;

        // Default settings for properties on the ChatModel in the backend
        // that are assumed by the client when it is a OpenChatResponse
        // eslint-disable-next-line no-prototype-builtins
        if (chat.hasOwnProperty("nextView")) {
            const openChatResponse = chat as OpenChatResponse;
            this.nextView = openChatResponse.nextView;
            this.directLineToken = openChatResponse.directLineToken;
            this.lastBotActivityReceivedDate =
                openChatResponse.lastBotActivityReceivedDate;
            this.lastBotActivityId = openChatResponse.lastBotActivityId;
            this.lastJoinableDate = openChatResponse.lastJoinableDate;
            this.isCustomerTimedOut = openChatResponse.isCustomerTimedOut;
            this.hasOpenChat = openChatResponse.hasOpenChat;
            this.isCustomerTimedOut = openChatResponse.isCustomerTimedOut;
            this.lastBotActivityId = openChatResponse.lastBotActivityId;
            this.isPlatinum = openChatResponse.isPlatinum;
        } else {
            // Default values if it is a CreateChatResponse
            this.hasOpenChat = false;
            this.isCustomerTimedOut = false;
            this.lastBotActivityId = "";
        }
    }
}
