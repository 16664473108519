import React from "react";
import { Space, Typography, List, Image, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
const { Text, Link } = Typography;
import { individual } from "../../../Shared/Definitions";
import "./ResolutionListItem.less";

const ResolutionListItem = (props: any): React.JSX.Element => {
    const {
        resolutionList,
        itemList,
        item,
        index,
        costPerItem,
        isReadOnly,
        isSubmitting,
        setResolutionList,
        "data-testid": dataTestId,
        additionalCloseButtonHandlerFunction,
        additionalItemSpaceEntry,
        addScreenReaderMessage,
        keyValue,
    } = props;
    const getItemUnitOfMeasureDisplay = (item: any): string => {
        if (item.unitsPerPackaging === 1) return "Qty";
        return item.returnType === individual
            ? "Individual Item(s)"
            : `${item.unitOfMeasure}(s)`;
    };

    const handleRemoveItem = (): void => {
        addScreenReaderMessage(
            `${item.itemDescription} has been removed from the resolution list`
        );

        const itemIndex = itemList.findIndex(
            (listItem: any) =>
                listItem.clarkitbizOrderItemId === item.clarkitbizOrderItemId
        );
        itemList[itemIndex].isChecked = false;
        itemList[itemIndex].resolutionQuantity = 0;
        itemList[itemIndex].returnType = null;

        resolutionList.splice(index, 1);
        setResolutionList([...resolutionList]);
        additionalCloseButtonHandlerFunction?.();
    };

    return (
        <List.Item
            className="item-resolution-list__item__wrapper"
            data-testid={`${dataTestId}-resolution-list-item`}
            key={keyValue}
        >
            <div className="item-resolution-list__item">
                <Image
                    src={item.itemThumbnailUrl}
                    preview={false}
                    alt=""
                    data-testid="resolution-list-item-image"
                />
                <Space size={4}>
                    <Space size={0}>
                        <Link
                            href={item.storeUrl}
                            target="_blank"
                            style={{
                                display: "inline-block",
                                width: "90%",
                            }}
                            data-testid="resolution-list-item-link"
                        >
                            {item.itemDescription}
                        </Link>
                    </Space>
                    <Space
                        size={0}
                        className="item-resolution-list__item__info"
                        data-testid="resolution-list-item-info"
                    >
                        <Text data-testid="resolution-list-item-info-item-number">
                            <span className="sr-only">Item</span>#
                            {(item.itemNumber as string).toUpperCase()}
                        </Text>
                        <Text data-testid="resolution-list-item-info-quantity">{`${
                            item.resolutionQuantity || 0
                        } ${getItemUnitOfMeasureDisplay(item)}`}</Text>
                        <Text data-testid="resolution-list-item-info-price">
                            $
                            {item.returnType === individual &&
                            item.unitsPerPackaging > 1
                                ? costPerItem(item).toFixed(2)
                                : item.price.toFixed(2)}
                        </Text>
                    </Space>
                    {additionalItemSpaceEntry && (
                        <Space size={0}>{additionalItemSpaceEntry}</Space>
                    )}
                </Space>
                {!isReadOnly && item.quantity != 0 && (
                    <Button
                        icon={
                            <>
                                <CloseOutlined />
                                <span className="sr-only">
                                    Remove {item.itemDescription}
                                </span>
                            </>
                        }
                        size="small"
                        onClick={handleRemoveItem}
                        disabled={isSubmitting}
                        data-testid="resolution-list-item-remove-button"
                    />
                )}
            </div>
            {!isReadOnly && props.children && (
                <Space
                    size={0}
                    className="item-resolution-list__item__children"
                    data-testid="resolution-list-item-children"
                >
                    {props.children}
                </Space>
            )}
        </List.Item>
    );
};

export default ResolutionListItem;
