import "./BotChatFeedback.less";
import { useCallback, useEffect, useRef, useState } from "react";
import {
    Typography,
    Card,
    Radio,
    Space,
    Button,
    Checkbox,
    Form,
    Modal,
    Input,
    Divider,
} from "antd";
const { Text, Title } = Typography;
const { TextArea } = Input;
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { BotFeedbackOption } from "@Api/dtos/chat/BotFeedbackOption";
import { ChatBotFeedbackError } from "@Api/dtos/chat/ChatBotFeedbackError";
import { ValidateStatus } from "@Components/shared/Form/ValidateStatus";
import {
    viewStateChanged,
    viewAndChatStateChanged,
    operatorsOnlineChanged,
    selectSecondsSinceLastJoinable,
} from "../../customerAppSlice";
import { ViewState } from "../../viewState";
import { ChatState } from "@Models/chat/ChatState";
import { CreateChatResponse } from "@Api/dtos/chat/CreateChatResponse";
import { OpenChatResponse } from "@Api/dtos/chat/OpenChatResponse";
import { SubmitChatBotFeedbackResponse } from "@Api/dtos/chat/SubmitChatBotFeedbackResponse";
import { PersistedMessageType } from "@Src/hubs/chat/dtos/PersistedMessageType";
import { ClientMessageType } from "@Hubs/chat/dtos/ClientMessageType";
import { SubmitChatBotFeedbackRequest } from "@Api/dtos/chat/SubmitChatBotFeedbackRequest";
import { clearTimeout, setTimeout } from "timers";
import { CustomerChatModel } from "@Models/chat/CustomerChatModel";
import { customerServiceDeptName } from "@Src/tenantConfiguration/SharedTenantConfiguration";
import FeedbackView from "../FeedbackView/FeedbackView";
import RequiredSymbol from "../RequiredSymbol/RequiredSymbol";
import FeedbackResults from "../FeedbackResult/FeedbackResults";
import { ResultMessage } from "../FeedbackResult/ResultMessage";
import HelpfulFormItems from "../HelpfulFormItems/HelpfulFormItems";
import { screenReaderOnlyCharacterCountSpan } from "../../Shared/utilities";

export interface BotChatFeedbackProps {
    connectToChat(chatResponse?: CreateChatResponse | OpenChatResponse): void;
    chat?: CustomerChatModel;
}

interface ModalMessage {
    title: string;
    message: string;
}

interface FormFields {
    wasHelpful?: boolean;
    positiveFeedback: string;
    notHelpfulReason?: number;
    negativeFeedback: string;
    additionalHelpNeeded?: boolean;
    nextAction?: BotFeedbackOption;
    emailMessage?: string;
    transcriptRequested?: boolean;
}

const BotChatFeedback = ({
    connectToChat,
    chat,
}: BotChatFeedbackProps): JSX.Element => {
    const [form] = Form.useForm();
    const [isResultViewVisible, setIsResultViewVisible] =
        useState<boolean>(false);
    const [confirmations, setConfirmations] = useState<ResultMessage[]>([]);
    const [submissionError, setSubmissionError] = useState<ResultMessage>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [recoverableError, setRecoverableError] = useState<string>();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<ModalMessage>();
    const [wereAnyMessagesSent, setWereAnyMessagesSent] =
        useState<boolean>(false);
    const [emailMessageLength, setEmailMessageLength] = useState(0);
    const emailMessageMaxLength = 2000;

    const dispatch = useAppDispatch();
    const chatApi = useAppSelector((state) => state.customerApp.chatApi);
    const messages = useAppSelector((state) => state.customerApp.messages);
    const operatorsOnline = useAppSelector(
        (state) => state.customerApp.operatorsOnline
    );
    const botNotHelpfulReasons = useAppSelector(
        (state) => state.customerApp.botChatNotHelpfulReasons
    );
    const totalFeedbackScreenVisibleDurationInSeconds = useAppSelector(
        (state) =>
            state.customerApp.applicationSettings
                ?.totalFeedbackScreenVisibleDurationInSeconds ?? 3600
    );
    const secondsSinceLastJoinable = useAppSelector(
        selectSecondsSinceLastJoinable
    );

    const customerIdleTimeout = useRef<NodeJS.Timeout>();

    const confirmationMessages: {
        [confirmationType: string]: ResultMessage;
    } = {
        TranscriptRequested: {
            title: "Success, the transcript is on its way to",
            message: chat?.email ?? "",
            dataTestId: "bot-chat-feedback-transcript-requested-success",
        },
        EmailUs: {
            title: "Success, your email was sent!",
            message:
                `Our ${customerServiceDeptName} Specialists received your ` +
                "email and will respond within 1-2 business days. If you " +
                "need additional help unrelated to the email you sent, " +
                "please feel free to chat back in!",
            dataTestId: "bot-chat-feedback-email-us-success",
        },
    };

    const thankYouMessage = "Thank you, we appreciate your feedback!";

    useEffect(() => {
        customerIdleTimeout.current = setTimeout(function () {
            dispatch(viewStateChanged(ViewState.TimedOut));
        }, 1000 *
            Math.max(
                totalFeedbackScreenVisibleDurationInSeconds -
                    secondsSinceLastJoinable,
                0
            ));

        return (): void => {
            if (customerIdleTimeout.current) {
                clearTimeout(customerIdleTimeout.current);
            }
        };
    }, [
        chat?.lastJoinableDate,
        dispatch,
        totalFeedbackScreenVisibleDurationInSeconds,
        secondsSinceLastJoinable,
    ]);

    useEffect((): void => {
        for (const message of messages) {
            if (
                message.messageType === PersistedMessageType.BotText ||
                message.messageType === PersistedMessageType.Text ||
                message.messageType === PersistedMessageType.Image ||
                message.messageType === ClientMessageType.SmartInclude
            ) {
                setWereAnyMessagesSent(true);
                break;
            }
        }
    }, [messages]);

    useEffect(() => {
        chatApi.updateSawFeedbackScreen();
    }, [chatApi]);

    const runPostSubmitActions = useCallback(
        (option: BotFeedbackOption): Promise<boolean> => {
            if (!chat) {
                return Promise.reject();
            }

            if (option === BotFeedbackOption.ChatWithRep) {
                return chatApi
                    .changeFromBotToRep({
                        allowLivePark: true,
                        wasAfterFeedback: true,
                    })
                    .then((newChatState: ChatState) => {
                        const chatWithNewState = {
                            ...chat,
                            state: newChatState,
                        };

                        connectToChat(chatWithNewState);
                        if (newChatState == ChatState.TalkingToRep) {
                            dispatch(
                                viewAndChatStateChanged({
                                    viewState: ViewState.TalkingToRep,
                                    chatState: newChatState,
                                })
                            );
                        }
                        return true;
                    })
                    .catch((error) => {
                        console.error(error);
                        dispatch(viewStateChanged(ViewState.Error));
                        return false;
                    });
            } else {
                return Promise.resolve(true);
            }
        },
        [dispatch, chatApi, chat, connectToChat]
    );

    const handleRepNotAvailable = useCallback((): void => {
        dispatch(operatorsOnlineChanged(false));
        setIsResultViewVisible(false);

        form.setFields([
            {
                name: "nextAction",
                value: undefined,
                errors: [],
            },
        ]);

        console.error("Error occurred with transferring to rep.");
        setModalMessage({
            title: "Operators are currently offline.",
            message:
                "We're sorry, but all operators are currently offline. Please choose another option.",
        });
        setIsModalVisible(true);
    }, [
        dispatch,
        form,
        setIsResultViewVisible,
        setModalMessage,
        setIsModalVisible,
    ]);

    const handleCloseModal = useCallback((): void => {
        setModalMessage(undefined);
        setIsModalVisible(false);
    }, [setModalMessage, setIsModalVisible]);

    const handleErrorSubmittingFeedback = useCallback(
        (response?: SubmitChatBotFeedbackResponse): void => {
            let shouldAdvanceScreen = true;
            if (!response) {
                console.error(
                    "Unrecoverable occurred submitting ChatBot feedback."
                );
                setSubmissionError({
                    title: "We're sorry!",
                    message: "There was an error submitting your feedback.",
                    dataTestId: "bot-chat-feedback-no-response-error",
                });
            } else if (
                response.error === ChatBotFeedbackError.Email ||
                response.isRecoverableStatusCode
            ) {
                console.error(
                    "Recoverable error occurred, allowing resubmission:",
                    response
                );
                setRecoverableError(
                    "An unexpected error occurred, please try again."
                );
                shouldAdvanceScreen = false;
            } else if (response.error === ChatBotFeedbackError.Transcript) {
                console.error(
                    "Error occurred with sending transcript.",
                    response
                );
                setSubmissionError({
                    title: "We're sorry!",
                    message: "We were unable to send a transcript.",
                    dataTestId: "bot-chat-feedback-transcript-error",
                });
            } else {
                console.error(
                    "Unrecoverable occurred submitting ChatBot feedback for, type '%s'",
                    response.error
                );
                setSubmissionError({
                    title: "We're sorry!",
                    message: "There was an error submitting your feedback.",
                    dataTestId: "bot-chat-feedback-unrecoverable-error",
                });
            }
            setIsResultViewVisible(shouldAdvanceScreen);
        },
        [setRecoverableError]
    );

    if (!chat) {
        return <></>;
    }

    const indentedFormItemProps = {
        labelCol: { offset: 1 },
        wrapperCol: { offset: 1 },
    };

    let formValidateStatus = ValidateStatus.none;

    if (isSubmitting) {
        formValidateStatus = ValidateStatus.validating;
    } else if (recoverableError) {
        formValidateStatus = ValidateStatus.error;
    }

    const onNextActionChange = (isChatWithRepSelected: boolean): void => {
        form.setFields([
            {
                name: "emailMessage",
                value: undefined,
                errors: [],
            },
        ]);

        if (isChatWithRepSelected) {
            form.setFields([
                {
                    name: "transcriptRequested",
                    value: undefined,
                    errors: [],
                },
            ]);
        }

        validatePrecedingFields("nextAction");
    };

    const validatePrecedingFields = (changedField: string): void => {
        const allFields = Object.keys(form.getFieldsValue());

        const precedingFields = allFields.slice(
            0,
            allFields.indexOf(changedField)
        );

        form.validateFields(precedingFields);
    };

    const mapFormValuesToRequest = (
        formValues: FormFields
    ): SubmitChatBotFeedbackRequest => {
        let feedbackComments = undefined;

        if (formValues.positiveFeedback) {
            feedbackComments = formValues.positiveFeedback;
        } else if (formValues.negativeFeedback) {
            feedbackComments = formValues.negativeFeedback;
        }

        let selectedOption: BotFeedbackOption;

        if (formValues.nextAction === undefined && feedbackComments) {
            selectedOption = BotFeedbackOption.LeaveFeedback;
        } else if (formValues.nextAction === undefined) {
            selectedOption = BotFeedbackOption.None;
        } else {
            selectedOption = formValues.nextAction;
        }

        return {
            wasHelpful: formValues.wasHelpful,
            notHelpfulReasonId: formValues.notHelpfulReason,
            comments: feedbackComments,
            emailMessage: formValues.emailMessage,
            feedbackOption: selectedOption,
            wasTranscriptRequested: formValues.transcriptRequested ?? false,
        };
    };

    function handleResponseAndSetConfirmationForEmailFeedback(
        request: SubmitChatBotFeedbackRequest,
        response: SubmitChatBotFeedbackResponse
    ): void {
        if (response.success) {
            runPostSubmitActions(request.feedbackOption).then(() => {
                if (request.feedbackOption === BotFeedbackOption.EmailUs) {
                    setConfirmations((currentConfirmations) => {
                        return [
                            confirmationMessages.EmailUs,
                            ...currentConfirmations,
                        ];
                    });
                }
            });
        } else {
            handleErrorSubmittingFeedback(response);
        }
    }

    function setConfirmationForTranscriptRequested(
        request: SubmitChatBotFeedbackRequest,
        response: SubmitChatBotFeedbackResponse
    ): void {
        if (
            request.wasTranscriptRequested &&
            (response.success ||
                response.error !== ChatBotFeedbackError.Transcript)
        ) {
            setConfirmations((currentConfirmations) => {
                return [
                    ...currentConfirmations,
                    confirmationMessages.TranscriptRequested,
                ];
            });
        }
    }

    const handleSubmit = (formValues: FormFields): void => {
        setIsSubmitting(true);

        chatApi
            .getOperatorsOnlineStatus()
            .then((isOperatorOnline) => {
                if (
                    !isOperatorOnline &&
                    formValues.nextAction &&
                    formValues.nextAction === BotFeedbackOption.ChatWithRep
                ) {
                    handleRepNotAvailable();
                } else {
                    const request = mapFormValuesToRequest(formValues);

                    return chatApi
                        .submitChatBotFeedback(request)
                        .then((response) => {
                            if (customerIdleTimeout.current) {
                                clearTimeout(customerIdleTimeout.current);
                            }

                            setIsResultViewVisible(response.success);

                            setConfirmationForTranscriptRequested(
                                request,
                                response
                            );

                            handleResponseAndSetConfirmationForEmailFeedback(
                                request,
                                response
                            );
                        })
                        .catch((error) => {
                            console.error(
                                "Error occurred while submitting feedback:",
                                error
                            );
                            handleErrorSubmittingFeedback();
                        });
                }
            })
            .finally((): void => setIsSubmitting(false));
    };

    const isNotChatWithRep =
        form.getFieldValue("nextAction") === undefined ||
        form.getFieldValue("nextAction") !== BotFeedbackOption.ChatWithRep;

    const handleEmailMessageTextAreaChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ): void => {
        setEmailMessageLength(e.target.value.length);
        validatePrecedingFields("emailMessage");
    };

    return (
        <FeedbackView>
            <Space
                className="bot-chat-feedback"
                direction="vertical"
                align="center"
                size={0}
            >
                {!isResultViewVisible && (
                    <Card
                        title={
                            <h1 data-testid="bot-chat-feedback-card-header-text">
                                We would love to hear from you!
                            </h1>
                        }
                        className="bot-chat-feedback__card"
                    >
                        <Form
                            form={form}
                            onFinish={(formValues): void =>
                                handleSubmit(formValues)
                            }
                            requiredMark={false}
                            layout="vertical"
                        >
                            <Form.Item className="bot-chat-feedback__required-legend">
                                <span className="extra">
                                    <RequiredSymbol />
                                    Indicates a Required Field
                                </span>
                            </Form.Item>
                            <HelpfulFormItems
                                form={form}
                                notHelpfulReasons={botNotHelpfulReasons}
                            />
                            <Divider dashed />
                            <fieldset aria-required="true">
                                <legend>
                                    <h2 data-testid="bot-chat-feedback-additional-help-text">
                                        Do you need additional help?
                                    </h2>
                                </legend>
                                <Form.Item
                                    name="additionalHelpNeeded"
                                    className="bot-chat-feedback__primary-question"
                                >
                                    <Radio.Group
                                        name="additionalHelpNeeded"
                                        onChange={(): void => {
                                            form.setFields([
                                                {
                                                    name: "nextAction",
                                                    value: undefined,
                                                    errors: [],
                                                },
                                                {
                                                    name: "emailMessage",
                                                    value: undefined,
                                                    errors: [],
                                                },
                                            ]);
                                            validatePrecedingFields(
                                                "additionalHelpNeeded"
                                            );
                                        }}
                                    >
                                        <Radio value={false}>
                                            No, my question was answered.
                                        </Radio>
                                        <Radio value={true}>
                                            Yes, I need additional help.
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </fieldset>
                            <Form.Item noStyle shouldUpdate>
                                {(): JSX.Element => {
                                    const hasError: boolean =
                                        form.getFieldError("nextAction")
                                            .length > 0;

                                    const additionalHelpNeeded: boolean =
                                        form.getFieldValue(
                                            "additionalHelpNeeded"
                                        );
                                    return (
                                        <Form.Item
                                            name="nextAction"
                                            hidden={!additionalHelpNeeded}
                                            {...indentedFormItemProps}
                                            rules={[
                                                {
                                                    required:
                                                        additionalHelpNeeded,
                                                    message: <></>,
                                                },
                                            ]}
                                        >
                                            <Radio.Group
                                                name="howWeMayAssist"
                                                onChange={(e): void =>
                                                    onNextActionChange(
                                                        e.target.value ===
                                                            BotFeedbackOption.ChatWithRep
                                                    )
                                                }
                                            >
                                                <fieldset>
                                                    <legend
                                                        className={
                                                            hasError
                                                                ? "ant-form-item-explain-error"
                                                                : undefined
                                                        }
                                                    >
                                                        <RequiredSymbol />
                                                        Please select how we may
                                                        assist you.
                                                    </legend>
                                                    {operatorsOnline && (
                                                        <Radio
                                                            value={
                                                                BotFeedbackOption.ChatWithRep
                                                            }
                                                        >
                                                            Chat with a Rep
                                                        </Radio>
                                                    )}
                                                    <Radio
                                                        value={
                                                            BotFeedbackOption.EmailUs
                                                        }
                                                    >
                                                        Email Us&nbsp;
                                                        <span className="extra">
                                                            (We typically
                                                            respond within 1
                                                            business day)
                                                        </span>
                                                    </Radio>
                                                </fieldset>
                                            </Radio.Group>
                                        </Form.Item>
                                    );
                                }}
                            </Form.Item>
                            <Form.Item
                                noStyle
                                shouldUpdate={(previous, current): boolean =>
                                    previous.nextAction !== current.nextAction
                                }
                            >
                                {(): JSX.Element => {
                                    const isEmailUsSelected =
                                        form.getFieldValue("nextAction") ===
                                        BotFeedbackOption.EmailUs;
                                    return (
                                        <Form.Item
                                            name="emailMessage"
                                            className="bot-chat-feedback__textarea-field indented"
                                            labelCol={{ offset: 2 }}
                                            wrapperCol={{ offset: 2 }}
                                            hidden={!isEmailUsSelected}
                                            label={
                                                <>
                                                    <RequiredSymbol />
                                                    Please add an email message.
                                                </>
                                            }
                                            rules={[
                                                {
                                                    required: isEmailUsSelected,
                                                    message:
                                                        "You must enter a message.",
                                                },
                                            ]}
                                        >
                                            <TextArea
                                                placeholder="Add Email Message"
                                                autoSize={{
                                                    minRows: 2,
                                                    maxRows: 7,
                                                }}
                                                maxLength={
                                                    emailMessageMaxLength
                                                }
                                                showCount
                                                onChange={
                                                    handleEmailMessageTextAreaChange
                                                }
                                                onBlur={(e): void => {
                                                    form.setFieldsValue({
                                                        emailMessage:
                                                            e.target.value.trim(),
                                                    });
                                                }}
                                                data-testid="bot-chat-feedback-additional-help-email-message"
                                            />
                                            {screenReaderOnlyCharacterCountSpan(
                                                emailMessageLength,
                                                emailMessageMaxLength
                                            )}
                                        </Form.Item>
                                    );
                                }}
                            </Form.Item>
                            <Divider dashed />
                            {wereAnyMessagesSent && (
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(
                                        previous,
                                        current
                                    ): boolean =>
                                        previous.nextAction !==
                                        current.nextAction
                                    }
                                >
                                    {(): JSX.Element => {
                                        const isChatWithRepSelected =
                                            form.getFieldValue("nextAction") ===
                                            BotFeedbackOption.ChatWithRep;
                                        return (
                                            <Form.Item
                                                name="transcriptRequested"
                                                valuePropName="checked"
                                            >
                                                <Checkbox
                                                    onChange={(): void =>
                                                        validatePrecedingFields(
                                                            "transcriptRequested"
                                                        )
                                                    }
                                                    disabled={
                                                        isChatWithRepSelected
                                                    }
                                                >
                                                    Request a transcript of this
                                                    chat via email.
                                                </Checkbox>
                                            </Form.Item>
                                        );
                                    }}
                                </Form.Item>
                            )}
                            <Form.Item
                                noStyle
                                shouldUpdate={(previous, current): boolean =>
                                    previous.nextAction !== current.nextAction
                                }
                            >
                                {(): JSX.Element => {
                                    let submitButtonText;
                                    const nextAction =
                                        form.getFieldValue("nextAction");

                                    switch (nextAction) {
                                        case BotFeedbackOption.EmailUs:
                                            submitButtonText = "Send Email";
                                            break;

                                        case BotFeedbackOption.ChatWithRep:
                                            submitButtonText = "Chat Now";
                                            break;

                                        default:
                                            submitButtonText =
                                                "Submit Feedback";
                                            break;
                                    }

                                    return (
                                        <Form.Item
                                            validateStatus={formValidateStatus}
                                            help={recoverableError}
                                        >
                                            <Button
                                                htmlType="submit"
                                                type="primary"
                                                disabled={isSubmitting}
                                                block
                                            >
                                                {submitButtonText}
                                            </Button>
                                        </Form.Item>
                                    );
                                }}
                            </Form.Item>
                        </Form>
                    </Card>
                )}
                {isResultViewVisible && submissionError && (
                    <FeedbackResults
                        results={[
                            { flavor: "error", message: submissionError },
                        ]}
                    />
                )}
                {isResultViewVisible &&
                    !submissionError &&
                    isNotChatWithRep && (
                        <>
                            <Space
                                className="bot-chat-feedback__submitted"
                                size={0}
                            >
                                <Title
                                    level={1}
                                    data-testid="bot-chat-feedback-thank-you-text"
                                >
                                    {thankYouMessage}
                                </Title>
                            </Space>
                            {confirmations.length > 0 && (
                                <FeedbackResults
                                    results={confirmations.map(
                                        (confirmation) => {
                                            return {
                                                flavor: "success",
                                                message: confirmation,
                                            };
                                        }
                                    )}
                                />
                            )}
                        </>
                    )}
                <Modal
                    open={isModalVisible}
                    onCancel={(): void => handleCloseModal()}
                    footer={null}
                    className="bot-chat-feedback__modal"
                >
                    <ExclamationCircleOutlined className="bot-chat-feedback__modal-icon" />
                    <Title className="bot-chat-feedback__modal-title" level={2}>
                        {modalMessage?.title}
                    </Title>
                    <Text className="bot-chat-feedback__modal-message">
                        {modalMessage?.message}
                    </Text>
                </Modal>
            </Space>
        </FeedbackView>
    );
};

export default BotChatFeedback;
