import { Button } from "antd";
import { useAppDispatch, useAppSelector } from "../hooks";
import { FailureType } from "@Models/customer/FailureType";
import { ChatState } from "@Models/chat/ChatState";
import { ViewState } from "../viewState";
import {
    clearChatBanner,
    viewAndChatStateChanged,
    viewStateChanged,
} from "../customerAppSlice";

const PlatinumChatWithRepBannerMessage = (): JSX.Element => {
    const chatApi = useAppSelector((state) => state.customerApp.chatApi);
    const appDispatch = useAppDispatch();

    const TransferToRepresentative = async (): Promise<void> => {
        try {
            const chatState = await chatApi.changeFromBotToRep({
                allowLivePark: true,
                wasAfterFeedback: false,
                failureType: FailureType.CreateDirectLine,
            });

            let viewState = ViewState.Error;

            if (chatState == ChatState.Parked) {
                viewState = ViewState.Parked;
            } else if (chatState == ChatState.TalkingToRep) {
                viewState = ViewState.TalkingToRep;
            }

            appDispatch(
                viewAndChatStateChanged({
                    viewState: viewState,
                    chatState: chatState,
                })
            );

            appDispatch(clearChatBanner());
        } catch {
            appDispatch(viewStateChanged(ViewState.Error));
        }
    };

    return (
        <>
            <b data-testid="customer-chat-banner-message">
                Want to talk to a specialist?{" "}
            </b>
            <Button
                type="default"
                className="chat-banner_button"
                data-testid="customer-chat-banner-chat-with-rep-button"
                onClick={TransferToRepresentative}
            >
                Chat with the Platinum Team
            </Button>
        </>
    );
};

export default PlatinumChatWithRepBannerMessage;
