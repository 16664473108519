import "./BotFeedbackDecorator.less";
import React, { useEffect, useState } from "react";
import { LikeOutlined, DislikeOutlined } from "@ant-design/icons";
import { useAppSelector } from "../hooks";
import { AddMessageFeedbackRequest } from "@Src/hubs/chat/dtos/AddMessageFeedbackRequest";
import ChatIcon from "@Images/chat-specialist.svg";
import { ProductBotChannelData } from "@Models/chatBot/ProductBotChannelData";

interface BotFeedbackDecoratorProps {
    messageId: string;
    qnaNumber?: number;
    knowledgeBaseId?: number;
    productBotChannelData?: ProductBotChannelData;
    onChatWithSpecialistClick: () => void;
    onFeedbackChange: (
        isHelpful: boolean,
        productBotInteractionKey?: string
    ) => void;
}

const BotFeedbackDecorator: React.FC<BotFeedbackDecoratorProps> = (props) => {
    const chatBotApi = useAppSelector((state) => state.customerApp.chatBotApi);
    const chat = useAppSelector((state) => state.customerApp.chat);
    const [isLiked, setIsLiked] = useState(false);
    const [isDisliked, setIsDisliked] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const { messageId, qnaNumber, knowledgeBaseId, productBotChannelData } =
        props;

    const operatorsOnline = useAppSelector(
        (state) => state.customerApp.operatorsOnline
    );

    useEffect(() => {
        if (isInitialLoad && chat?.dboChatId) {
            chatBotApi
                .getMessageFeedback({
                    chatId: chat?.dboChatId,
                    messageId: messageId,
                })
                .then((messageFeedback) => {
                    if (messageFeedback != null) {
                        setIsLiked(messageFeedback);
                        setIsDisliked(!messageFeedback);
                    }
                })
                .finally(() => {
                    setIsInitialLoad(false);
                });
        }
    }, [chatBotApi, isInitialLoad, messageId, chat?.dboChatId]);

    // Preload the chat with specialist image so there is no delay when not helpful is clicked
    useEffect(() => {
        const img = new Image();
        img.src = ChatIcon;
    }, []);

    const handleReactionToggle = (isLike: boolean): void => {
        const isNewLike = isLike ? !isLiked : false;
        const isNewDislike = isLike ? false : !isDisliked;

        setIsLiked(isNewLike);
        setIsDisliked(isNewDislike);

        if (chat?.dboChatId) {
            if (isNewLike || isNewDislike) {
                const addFeedbackRequest: AddMessageFeedbackRequest = {
                    messageId,
                    chatId: chat.dboChatId,
                    qnaNumber,
                    knowledgeBaseId,
                    wasHelpful: isNewLike, // true if like, false if dislike
                    dateRated: new Date(),
                };
                chatBotApi.addMessageFeedback(addFeedbackRequest);
                props.onFeedbackChange(
                    isNewLike,
                    productBotChannelData?.InteractionKey
                );
            } else {
                // If both are false, remove feedback
                chatBotApi.removeMessageFeedback({
                    chatId: chat.dboChatId,
                    messageId,
                });
            }
        }
    };

    return (
        <div className="bot-feedback-decorator">
            <div className="bot-feedback-decorator__content">
                {props.children}
            </div>
            <div className="bot-feedback-decorator__grouping">
                <div className="bot-feedback-decorator__question">
                    Was this helpful?
                </div>
                <ul className="bot-feedback-decorator__buttons">
                    <li>
                        <button
                            className={`bot-feedback-decorator__buttons__like-button${
                                isLiked ? "--liked" : ""
                            }`}
                            onClick={(): void => handleReactionToggle(true)}
                        >
                            <LikeOutlined
                                className={`bot-feedback-decorator__buttons__like-button__icon${
                                    isLiked ? "--liked" : ""
                                }`}
                            />
                            Helpful
                        </button>
                    </li>
                    <li>
                        <button
                            className={`bot-feedback-decorator__buttons__dislike-button${
                                isDisliked ? "--disliked" : ""
                            }`}
                            onClick={(): void => handleReactionToggle(false)}
                        >
                            <DislikeOutlined
                                className={`bot-feedback-decorator__buttons__dislike-button__icon${
                                    isDisliked ? "--disliked" : ""
                                }`}
                            />
                            Not Helpful
                        </button>
                    </li>
                </ul>
                {operatorsOnline && isDisliked && (
                    <button
                        className="bot-feedback-decorator__buttons__chat-button"
                        onClick={props.onChatWithSpecialistClick}
                    >
                        <img
                            src={ChatIcon}
                            alt="chat-with-specialist"
                            className="bot-feedback-decorator__buttons__chat-button__icon"
                        />
                        Chat with Specialist
                    </button>
                )}
                {productBotChannelData && (
                    <div className="bot-feedback-decorator__ai-disclaimer">
                        AI-generated answer for{" "}
                        <a
                            href={productBotChannelData.ProductPageLink}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Item # {productBotChannelData.ItemNumber}
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BotFeedbackDecorator;
