/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AttachmentType } from "@Models/chatBot/AttachmentType";
import { ActivityType } from "@Models/chatBot/ActivityType";
import { CreateBotTextMessageRequest } from "@Models/chatBot/CreateBotTextMessageRequest";
import { InsertCustomActivityMessageRequest } from "@Src/hubs/chat/dtos/InsertCustomActivityMessageRequest";
import { PersistedMessageType } from "@Src/hubs/chat/dtos/PersistedMessageType";

export function makeSimpleTextMessage(
    text: string,
    activityId: string,
    receivedDate: Date,
    ignoreAnalysis = false,
    productBotInteractionKey = ""
): CreateBotTextMessageRequest {
    return {
        text: text,
        activityId: activityId,
        receivedDate: receivedDate,
        ignoreAnalysis: ignoreAnalysis,
        productBotInteractionKey: productBotInteractionKey,
    };
}

function makeButtonTextMessage(
    text: string,
    activityId: string,
    receivedDate: Date
): CreateBotTextMessageRequest {
    return {
        text: text,
        isBotChoice: true,
        activityId: activityId,
        receivedDate: receivedDate,
    };
}

export function convertAttachmentsToMessages(
    attachment: any,
    activityId: string,
    receivedDate: Date,
    chatId: string,
    chatApi: any,
    ignoreAnalysis: boolean
): CreateBotTextMessageRequest[] {
    const contentType = attachment.contentType;
    if (contentType === AttachmentType.HeroCard) {
        const content = attachment.content;
        if (content !== undefined) {
            let messages = [];
            const contentText = content.text;
            if (
                contentText !== undefined &&
                contentText !== null &&
                contentText !== ""
            ) {
                messages.push(
                    makeSimpleTextMessage(
                        contentText || "",
                        activityId,
                        receivedDate,
                        ignoreAnalysis
                    )
                );
            }
            const buttons = content.buttons;
            if (buttons && Array.isArray(buttons)) {
                messages = messages.concat(
                    buttons.map(function (button) {
                        return makeButtonTextMessage(
                            button.title || "",
                            activityId,
                            receivedDate
                        );
                    })
                );
            }
            return messages;
        }
    } else if (contentType === AttachmentType.AdaptiveCard) {
        const actions = attachment.content.actions;

        if (actions && Array.isArray(actions)) {
            return actions.map((action) =>
                makeButtonTextMessage(
                    action.title || "",
                    activityId,
                    receivedDate
                )
            );
        }
    } else if (
        contentType === AttachmentType.OrderTracking ||
        contentType === AttachmentType.TaxTracking ||
        contentType === AttachmentType.ItemRefundReship ||
        contentType === AttachmentType.ItemReturns ||
        contentType === AttachmentType.FoodQualityIssue
    ) {
        let messageType: PersistedMessageType;
        if (contentType === AttachmentType.OrderTracking) {
            messageType = PersistedMessageType.OrderTracking;
        } else if (contentType === AttachmentType.TaxTracking) {
            messageType = PersistedMessageType.TaxExemptionStatus;
        } else if (contentType === AttachmentType.ItemRefundReship) {
            messageType = PersistedMessageType.RefundReship;
        } else if (contentType === AttachmentType.FoodQualityIssue) {
            messageType = PersistedMessageType.FoodQualityIssue;
        } else {
            messageType = PersistedMessageType.Returns;
        }

        const messageRequest: InsertCustomActivityMessageRequest = {
            chatId: chatId,
            jsonData: JSON.stringify(attachment.content),
            senderName: "Pepper",
            messageType: messageType,
            activityId: activityId,
        };
        chatApi?.insertCustomActivityMessage(messageRequest);
    }

    return [];
}

export function convertActivityToMessages(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    activity: any,
    activityId: string,
    chatId: string,
    chatApi: any
): CreateBotTextMessageRequest[] {
    if (activity.type === ActivityType.Message) {
        let messages = Array<CreateBotTextMessageRequest>();

        const activityText = activity.text;
        const ignoreAnalysis: boolean = activity.channelData?.IgnoreAnalysis;
        const productBotInteractionKey: string =
            activity.channelData?.ProductBotAnswer?.InteractionKey ?? "";

        // For some reason we get a message activities with an empty string as the text after QnA followup prompts
        if (
            activityText !== undefined &&
            activityText !== null &&
            activityText !== ""
        ) {
            // Simple message activity
            messages.push(
                makeSimpleTextMessage(
                    activityText,
                    activityId,
                    new Date(activity.timestamp as number),
                    ignoreAnalysis,
                    productBotInteractionKey
                )
            );
        }

        const attachments = activity.attachments;
        if (attachments && Array.isArray(attachments)) {
            attachments.forEach(function (attachment) {
                messages = messages.concat(
                    convertAttachmentsToMessages(
                        attachment,
                        activityId,
                        new Date(activity.timestamp as number),
                        chatId,
                        chatApi,
                        ignoreAnalysis
                    )
                );
            });
        }

        return messages;
    }

    return [];
}
